import { defaultContentValues } from "../../../__generated__/editor";
import { language_enum, offer_goal_enum } from "../../../__generated__/graphql";
import createIntl from "../../../common/i18n";

export interface OfferDefaultValues {
  headline: string;
  content: any;
  buttonText: string;
  confirmationConfirmButtonText: string;
  confirmationCancelButtonText: string;
}

export const getChangeFrequencyDefaults = (
  language: language_enum,
  includeTag: boolean = true
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Change your delivery frequency",
      id: "jfzBxB",
    }),
    content: includeTag
      ? defaultContentValues["offer.retention.change_frequency.default"][
          language
        ]
      : defaultContentValues["offer.retention.change_frequency.no_tag"][
          language
        ],
    buttonText: intl.formatMessage({
      defaultMessage: "Change frequency",
      id: "Fhn802",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getChangePlanDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Try a different plan!",
      id: "uAjBim",
    }),
    content: forPreview
      ? defaultContentValues["offer.retention.change_plan.preview"][language]
      : defaultContentValues["offer.retention.change_plan.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Change plan",
      id: "So9+9+",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getCouponDefaults = (
  goal: offer_goal_enum,
  language: language_enum
) => {
  const intl = createIntl(language);

  return {
    headline:
      goal === offer_goal_enum.conversion
        ? intl.formatMessage({
            defaultMessage: "Subscribe now and save!",
            id: "xXS6Hj",
          })
        : intl.formatMessage({
            defaultMessage: "Stay and save!",
            id: "Me139f",
          }),
    content:
      goal === offer_goal_enum.conversion
        ? defaultContentValues["offer.conversion.coupon.default"][language]
        : defaultContentValues["offer.retention.coupon.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getCustomDefaults = (language: language_enum) => {
  const intl = createIntl(language);

  return {
    headline: "",
    content: defaultContentValues["offer.retention.custom.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getDowngradeDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Downgrade your plan",
      id: "zO935D",
    }),
    content: forPreview
      ? defaultContentValues["offer.retention.downgrade.preview"][language]
      : defaultContentValues["offer.retention.downgrade.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Downgrade",
      id: "SduMNm",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Downgrade",
      id: "SduMNm",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getFreeGiftDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Get a free gift on us!",
      id: "z7HKjf",
    }),
    content: forPreview
      ? defaultContentValues["offer.retention.free_gift.preview"][language]
      : defaultContentValues["offer.retention.free_gift.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getModifySubscriptionDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: "",
    content: forPreview
      ? defaultContentValues["offer.retention.modify_subscription.preview"][
          language
        ]
      : defaultContentValues["offer.retention.modify_subscription.default"][
          language
        ],
    buttonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getPauseSubscriptionDefaults = (
  language: language_enum,
  includeTag: boolean = true
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Pause your subscription",
      id: "JR0kDl",
    }),
    content: includeTag
      ? defaultContentValues["offer.retention.pause_subscription.default"][
          language
        ]
      : defaultContentValues["offer.retention.pause_subscription.no_tag"][
          language
        ],
    buttonText: intl.formatMessage({
      defaultMessage: "Pause subscription",
      id: "Knaty6",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getProductSwapDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Try something different",
      id: "NdLPT2",
    }),
    content: forPreview
      ? defaultContentValues["offer.retention.product_swap.preview"][language]
      : defaultContentValues["offer.retention.product_swap.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Swap products",
      id: "WORGX7",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getRescheduleOrderDefaults = (language: language_enum) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Too much product?",
      id: "Eew1Sn",
    }),
    content:
      defaultContentValues["offer.retention.reschedule_order.default"][
        language
      ],
    skipButtonText: intl.formatMessage({
      defaultMessage: "Skip order",
      id: "VQOKzp",
    }),
    rescheduleButtonText: intl.formatMessage({
      defaultMessage: "Reschedule order",
      id: "y9L5Os",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getTrialExtensionDefaults = (language: language_enum) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Need more time?",
      id: "sdPvv5",
    }),
    content:
      defaultContentValues["offer.retention.trial_extension.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Extend trial",
      id: "I/rS4e",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

export const getUpgradeDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Upgrade your plan!",
      id: "2kHHbt",
    }),
    content: forPreview
      ? defaultContentValues["offer.retention.upgrade.preview"][language]
      : defaultContentValues["offer.retention.upgrade.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Upgrade",
      id: "0h/lPM",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Upgrade",
      id: "0h/lPM",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};

// @TODO
export const getReactivateDefaults = (
  language: language_enum,
  forPreview: boolean = false
) => {
  const intl = createIntl(language);

  return {
    headline: intl.formatMessage({
      defaultMessage: "Resubscribe and save!",
      id: "TO2z3/",
    }),
    content: forPreview
      ? defaultContentValues["offer.retention.change_plan.preview"][language]
      : defaultContentValues["offer.retention.change_plan.default"][language],
    buttonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationConfirmButtonText: intl.formatMessage({
      defaultMessage: "Accept offer",
      id: "/xro5W",
    }),
    confirmationCancelButtonText: intl.formatMessage({
      defaultMessage: "Nevermind",
      id: "64sfSN",
    }),
  };
};
