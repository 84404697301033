import gql from "graphql-tag";

export default gql`
  fragment AppOfferFragment on offer {
    id
    token
    name
    type
    goal
    created_at
    offer_tags {
      tag {
        id
        name
      }
    }
  }
`;
