import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  Control,
  Controller,
  FormState,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import tw from "twin.macro";

import {
  language_enum,
  platform_enum,
} from "../../../../__generated__/graphql";
import Editor from "../../../../common/editor2/Editor";
import FieldError from "../../../../common/form/FieldError";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldRowBlock from "../../../../common/form/FieldRowBlock";
import FormMode from "../../../../common/form/FormMode";
import SelectInput from "../../../../common/form/input/SelectInput";
import TextInput from "../../../../common/form/input/TextInput";
import MetadataInput from "../../../../common/MetadataInput";
import { DeleteButton } from "../../../public/flow/steps/SharedComponents";
import validateMetadata from "../../lib/validateMetadata";
import { ChangeFrequencyOfferFormValues } from "../types";

interface ChangeFrequencyOptionFormPartialProps {
  mode: FormMode;
  optionIndex: 1 | 2 | 3;
  optionCount: number;
  optionNumber: number;
  platform: platform_enum;
  editingLanguage: language_enum;
  initialTextValue?: string;
  initialTextValueKey?: string;
  onDelete: () => void;
  register: UseFormRegister<ChangeFrequencyOfferFormValues>;
  control: Control<ChangeFrequencyOfferFormValues, any>;
  watch: UseFormWatch<ChangeFrequencyOfferFormValues>;
  formState: FormState<ChangeFrequencyOfferFormValues>;
  isSubmitting: boolean;
}

const ChangeFrequencyOptionFormPartial: React.FunctionComponent<
  ChangeFrequencyOptionFormPartialProps
> = ({
  mode,
  optionIndex,
  optionCount,
  optionNumber,
  platform,
  editingLanguage,
  initialTextValue,
  initialTextValueKey,
  onDelete,
  register,
  control,
  watch,
  formState,
  isSubmitting,
}) => {
  const values = watch();

  const [metadataExpanded, setMetadataExpanded] = useState(false);

  return (
    <div tw="mt-3 first:mt-4 px-3 py-2 pb-2 border border-divider bg-gray-50 rounded shadow-sm">
      <div tw="flex">
        <strong>Option {optionNumber}</strong>
        {mode !== "edit" && (
          <div tw="ml-auto">
            <DeleteButton
              tw="-mr-1"
              type="button"
              disabled={optionCount < 2}
              onClick={onDelete}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </DeleteButton>
          </div>
        )}
      </div>
      <fieldset disabled={isSubmitting || mode === "edit"}>
        <FieldRow tw="border-gray-200">
          <FieldLabel>
            <label>Interval</label>
          </FieldLabel>
          <FieldInput>
            <SelectInput
              {...register(`option${optionIndex}Interval`)}
              defaultValue="month"
            >
              <>
                <option value="year">Years</option>
                <option value="month">Months</option>
                <option value="week">Weeks</option>
                <option value="day">Days</option>
              </>
            </SelectInput>
          </FieldInput>
        </FieldRow>
        <FieldRow
          css={optionCount < 2 ? tw`border-0` : undefined}
          tw="border-gray-200"
        >
          <FieldLabel>
            <label htmlFor="intervalCount">
              {values[`option${optionIndex}Interval`] === "month"
                ? "Months"
                : values[`option${optionIndex}Interval`] === "week"
                ? "Weeks"
                : values[`option${optionIndex}Interval`] === "year"
                ? "Years"
                : "Days"}
            </label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register(`option${optionIndex}IntervalCount`, {
                required: true,
                validate: (value) => {
                  if (Number(value) < 1) {
                    return "";
                  }

                  if (optionIndex === 1) {
                    if (values.option2Present) {
                      if (
                        values.option1Interval === values.option2Interval &&
                        Number(value) === Number(values.option2IntervalCount)
                      ) {
                        return "Option must be different than other options.";
                      }
                    }

                    if (values.option3Present) {
                      if (
                        values.option1Interval === values.option3Interval &&
                        Number(value) === Number(values.option3IntervalCount)
                      ) {
                        return "Option must be different than other options.";
                      }
                    }
                  }

                  if (optionIndex === 2) {
                    if (values.option1Present) {
                      if (
                        values.option2Interval === values.option1Interval &&
                        Number(value) === Number(values.option1IntervalCount)
                      ) {
                        return "Option must be different than other options.";
                      }
                    }

                    if (values.option3Present) {
                      if (
                        values.option2Interval === values.option3Interval &&
                        Number(value) === Number(values.option3IntervalCount)
                      ) {
                        return "Option must be different than other options.";
                      }
                    }
                  }

                  if (optionIndex === 3) {
                    if (values.option1Present) {
                      if (
                        values.option3Interval === values.option1Interval &&
                        Number(value) === Number(values.option1IntervalCount)
                      ) {
                        return "Option must be different than other options.";
                      }
                    }

                    if (values.option2Present) {
                      if (
                        values.option3Interval === values.option2Interval &&
                        Number(value) === Number(values.option2IntervalCount)
                      ) {
                        return "Option must be different than other options.";
                      }
                    }
                  }
                },
              })}
              type="number"
              id="intervalCount"
              width="xs"
              fieldError={formState.errors[`option${optionIndex}IntervalCount`]}
            />
            <FieldError
              error={formState.errors[`option${optionIndex}IntervalCount`]}
            />
            <FieldHint>Change frequency to this length of time.</FieldHint>
          </FieldInput>
        </FieldRow>
      </fieldset>
      <FieldRowBlock
        css={[tw`border-0 pb-1`, optionCount > 1 ? undefined : tw`hidden`]}
      >
        <FieldLabel>Text</FieldLabel>
        <Controller
          control={control}
          name={`option${optionIndex}Text`}
          render={({ field }) => (
            <Editor
              key={editingLanguage}
              isInline={true}
              tagsEnabled={false}
              linksEnabled={false}
              initialValue={initialTextValue}
              initialValueKey={initialTextValueKey}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      </FieldRowBlock>

      <div
        tw="border-t border-gray-200 pb-2 mt-4"
        css={[
          metadataExpanded ? tw`pb-0` : undefined,
          optionCount < 2 ? tw`mt-0` : undefined,
        ]}
      >
        <FieldLabel
          tw="pb-0 cursor-pointer ml-[-2px]"
          onClick={() => setMetadataExpanded(!metadataExpanded)}
        >
          <FontAwesomeIcon
            icon={metadataExpanded ? faChevronDown : faChevronRight}
            transform="shrink-3"
            fixedWidth
          />{" "}
          Metadata
        </FieldLabel>

        <div css={!metadataExpanded ? tw`overflow-hidden h-0` : undefined}>
          <FieldRow tw="border-0">
            <FieldInput tw="pb-2">
              <Controller
                control={control}
                name={`option${optionIndex}Metadata`}
                render={({ field }) => (
                  <MetadataInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{
                  validate: validateMetadata,
                }}
              />
              <FieldError error={formState.errors.metadata} />
            </FieldInput>
          </FieldRow>
        </div>
      </div>
    </div>
  );
};

export default ChangeFrequencyOptionFormPartial;
