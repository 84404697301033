import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Spinner from "../../../common/Spinner";
import ChargebeePaymentForm from "./ChargebeePaymentForm";
import { PaymentFormProps } from "./types";

interface ChargebeePaymentProps extends PaymentFormProps {
  site: string;
  publishableKey: string;
}

const ChargebeePayment: React.FunctionComponent<ChargebeePaymentProps> = ({
  site,
  publishableKey,
  ...props
}) => {
  const [ready, setReady] = useState(false);

  return (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags) => {
          if (!addedTags.scriptTags) {
            return;
          }

          for (const scriptTag of addedTags.scriptTags) {
            if (scriptTag.src === "https://js.chargebee.com/v2/chargebee.js") {
              scriptTag.addEventListener("load", () => {
                (window as any).Chargebee.init({
                  site,
                  publishableKey,
                });

                setReady(true);
              });
            }
          }
        }}
      >
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </Helmet>
      {ready ? <ChargebeePaymentForm {...props} /> : <Spinner />}
    </>
  );
};

export default ChargebeePayment;
