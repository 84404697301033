import {
  FlowSubscriptionFragment,
  platform_currency_enum,
} from "../../../__generated__/graphql";
import fruitsUri from "../../../assets/product-swap/fruits.svg";
import vegetablesUri from "../../../assets/product-swap/vegetables.svg";

const swappableProductsPreviewExampleData: FlowSubscriptionFragment = {
  __typename: "subscription",
  id: 1,
  platform_subscription: {
    __typename: "platform_subscription",
    platform_id: "1",
    swappable_ecommerce_products: [
      {
        __typename: "platform_subscription_plan_swappable_ecommerce_product",
        platform_ecommerce_product: {
          __typename: "platform_ecommerce_product",
          platform_id: "1",
          name: "Weekly Vegetables Box",
          image_url: vegetablesUri,
          platform_variants: [
            {
              __typename: "platform_variant",
              platform_id: "1",
              name: "Default Title",
              price: "14.99",
              currency: platform_currency_enum.usd,
              available: true,
              platform_variant_options: [
                {
                  __typename: "platform_variant_option",
                  key: "Title",
                  value: "Default Title",
                },
              ],
            },
          ],
          platform_plan_ecommerce_products: [],
        },
      },
      {
        __typename: "platform_subscription_plan_swappable_ecommerce_product",
        platform_ecommerce_product: {
          __typename: "platform_ecommerce_product",
          platform_id: "2",
          name: "Weekly Fruits Box",
          image_url: fruitsUri,
          platform_variants: [
            {
              __typename: "platform_variant",
              platform_id: "1",
              name: "Default Title",
              price: "14.99",
              currency: platform_currency_enum.usd,
              available: true,
              platform_variant_options: [
                {
                  __typename: "platform_variant_option",
                  key: "Title",
                  value: "Default Title",
                },
              ],
            },
          ],
          platform_plan_ecommerce_products: [],
        },
      },
    ],
    platform_subscription_plans: [
      {
        __typename: "platform_subscription_plan",
        platform_variant: {
          __typename: "platform_variant",
          platform_id: "preview",
          platform_variant_options: [
            {
              __typename: "platform_variant_option",
              key: "Title",
              value: "Default Title",
            },
          ],
          price: "26.99",
          currency: platform_currency_enum.usd,
          available: true,
          platform_ecommerce_product: {
            __typename: "platform_ecommerce_product",
            name: "Weekly Fruits & Vegetables Box",
            platform_id: "preview",
          },
        },
      },
    ],
  },
};

export default swappableProductsPreviewExampleData;
