import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Spinner from "../../../common/Spinner";
import RecurlyPaymentForm from "./RecurlyPaymentForm";
import { PaymentFormProps } from "./types";

interface RecurlyPaymentProps extends PaymentFormProps {
  publicKey: string;
  addressRequirements: string;
}

const RecurlyPayment: React.FunctionComponent<RecurlyPaymentProps> = ({
  publicKey,
  ...props
}) => {
  const [ready, setReady] = useState(false);

  return (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags) => {
          if (!addedTags.scriptTags) {
            return;
          }

          for (const scriptTag of addedTags.scriptTags) {
            if (scriptTag.src === "https://js.recurly.com/v4/recurly.js") {
              scriptTag.addEventListener("load", () => {
                setReady(true);
              });
            }
          }
        }}
      >
        <script src="https://js.recurly.com/v4/recurly.js" />
        <link rel="stylesheet" href="https://js.recurly.com/v4/recurly.css" />
      </Helmet>
      {ready ? (
        <RecurlyProvider publicKey={publicKey}>
          <Elements>
            <RecurlyPaymentForm {...props} />
          </Elements>
        </RecurlyProvider>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RecurlyPayment;
