import { faTicket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SubscriberOfferReactivatePlan } from "../../../__generated__/graphql";

export interface PlanSummaryProps {
  plan: SubscriberOfferReactivatePlan | undefined;
  description: React.ReactNode;
  baseCost: number | null;
  cost: number;
  baseClassName?: string;
}

const PlanSummary: React.FunctionComponent<PlanSummaryProps> = ({
  plan,
  description,
  baseCost,
  cost,
  baseClassName = "reactivate",
}) => (
  <>
    <div className={`${baseClassName}__reactivate-plan-summary`}>
      <div className={`${baseClassName}__reactivate-plan-summary__header`}>
        <span
          className={`${baseClassName}__reactivate-plan-summary__header__name`}
        >
          {plan?.name || "Plan"}
        </span>
        <div
          className={`${baseClassName}__reactivate-plan-summary__header__coupon`}
        >
          <span>
            <FontAwesomeIcon icon={faTicket} /> {description}
          </span>
        </div>
      </div>
      <div className={`${baseClassName}__reactivate-plan-summary__body`}>
        {!!baseCost && (
          <span
            className={`${baseClassName}__reactivate-plan-summary__body__old-price`}
          >
            {Intl.NumberFormat(
              navigator.languages ? [...navigator.languages] : "en-US",
              {
                style: "currency",
                currency: plan?.currency || "usd",
              }
            ).format(baseCost || 0)}
          </span>
        )}

        <div
          className={`${baseClassName}__reactivate-plan-summary__body__new-price`}
        >
          <span
            className={`${baseClassName}__reactivate-plan-summary__body__new-price__value`}
          >
            {Intl.NumberFormat(
              navigator.languages ? [...navigator.languages] : "en-US",
              {
                style: "currency",
                currency: plan?.currency || "usd",
              }
            ).format(cost || 0)}
          </span>
          <span
            className={`${baseClassName}__reactivate-plan-summary__body__new-price__currency`}
          >
            {plan?.currency === "usd" ? "USD" : ""}/month
          </span>
        </div>
      </div>
    </div>
  </>
);

export default PlanSummary;
