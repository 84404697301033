import {
  faPencil as faPencilLight,
  faTrash as faTrashLight,
} from "@fortawesome/pro-light-svg-icons";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw, { styled, theme } from "twin.macro";

import DropdownMenu from "../DropdownMenu";

export const BoxHeading = tw.div`text-gray-700 font-semibold uppercase text-sm mb-[0.1rem]`;

export const BoxContainer = tw.div`flex flex-wrap -m-1`;

type BoxProps = React.HTMLAttributes<HTMLDivElement> & {
  isOn: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  disableDelete?: boolean;
  onClickEdit?: (e: any) => void;
  onClickDelete?: (e: any) => void;
};

const BoxInner = styled.div<{
  isOn: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}>`
  ${tw`flex items-center font-semibold rounded-md text-white leading-6 m-1 cursor-pointer`}

  ${(props) => !props.isOn && tw`bg-gray-400!`}
  button {
    ${(props) => !props.isOn && tw`bg-gray-500! hover:bg-gray-600!`}
  }

  ${(props) =>
    props.disabled && tw`cursor-default opacity-50 pointer-events-none`}

  ${(props) => props.readOnly && tw`cursor-text`}
`;

const BoxText = tw.span`px-2 truncate`;

const BoxEditButton = styled.button`
  ${tw`box-content rounded-[5px] border border-white max-h-[20px] max-w-[20px] m-[2px] -ml-[2px]`}

  ${tw`
    relative outline-none
    active:shadow-inner active:outline-none
    disabled:cursor-default disabled:shadow-none disabled:opacity-50 disabled:pointer-events-none
    focus:outline-none transition-all duration-150 ease-in-out
  `}

  &:active:enabled > span {
    top: 1px;
  }
`;

export const Box: React.FunctionComponent<BoxProps> = ({
  isOn,
  readOnly,
  disabled,
  isEditable,
  isDeletable,
  disableDelete,
  onClickEdit = () => {},
  onClickDelete = () => {},
  children,
  ...props
}) => (
  <BoxInner isOn={isOn} readOnly={readOnly} disabled={disabled} {...props}>
    <BoxText>{children}</BoxText>
    {isEditable && !isDeletable && (
      <BoxEditButton
        onClick={(e) => {
          e.stopPropagation();
          onClickEdit(e);
        }}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            color="white"
            icon={faPencil}
            transform="shrink-5 up-1"
          />
        </span>
      </BoxEditButton>
    )}
    {isDeletable && !isEditable && (
      <BoxEditButton
        onClick={(e) => {
          e.stopPropagation();
          onClickDelete(e);
        }}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            color="white"
            icon={faTrash}
            transform="shrink-5 up-1"
          />
        </span>
      </BoxEditButton>
    )}
    {isDeletable && isEditable && (
      <DropdownMenu
        items={[
          {
            icon: faPencilLight,
            label: "Edit",
            onClick: (e: any) => {
              e.stopPropagation();
              onClickEdit(e);
            },
          },
          {
            icon: faTrashLight,
            label: "Delete",
            color: theme`colors.red.DEFAULT`,
            isDisabled: disableDelete,
            onClick: (e: any) => {
              e.stopPropagation();
              onClickDelete(e);
            },
          },
        ]}
        render={({ onClick }) => (
          <BoxEditButton
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            <span
              tw="flex items-center justify-center"
              className="fa-layers fa-fw"
            >
              <span tw="h-8">...</span>
            </span>
          </BoxEditButton>
        )}
        zIndex={50}
        placement="top-end"
      />
    )}
  </BoxInner>
);

export const AnyBox = styled(Box)`
  ${tw`bg-gray-800`}
`;

export const SegmentGroupBox = styled(Box)`
  ${tw`bg-purple-600 truncate`}

  button {
    ${tw`bg-purple-700 hover:bg-purple-800`}
  }
`;

export const QuestionOptionBox = styled(Box)`
  ${tw`bg-blue-500`}

  button {
    ${tw`bg-blue-600 hover:bg-blue-700`}
  }
`;

export const NoOfferBox = styled(Box)<{ isReallyOn: boolean }>`
  ${tw`bg-orange-300`}
  ${(props) => props.isReallyOn && tw`bg-red-500!`}
`;

export const OfferBox = styled(Box)`
  ${tw`bg-orange-300`}
`;

export const OfferTestBox = styled(Box)`
  ${tw`bg-orange-400`}
`;

export const OfferGroupBox = styled(Box)`
  ${tw`bg-orange-600`}

  button {
    ${tw`bg-orange-700 hover:bg-orange-800`}
  }
`;

export const FlowBox = styled(Box)`
  ${tw`bg-orange-300`}

  button {
    ${tw`bg-orange-400 hover:bg-orange-500`}
  }
`;

export const FlowTestBox = styled(Box)`
  ${tw`bg-orange-400`}

  button {
    ${tw`bg-orange-500 hover:bg-orange-600`}
  }
`;

export const DeflectionBox = styled(Box)`
  ${tw`bg-orange-300`}

  button {
    ${tw`bg-orange-400 hover:bg-orange-500`}
  }
`;

export const NoDeflectionBox = styled(Box)<{ isReallyOn: boolean }>`
  ${tw`bg-orange-300`}
  ${(props) => props.isReallyOn && tw`bg-red-500!`}
`;

export const OfferAutopilotBox = styled(Box)`
  ${tw`bg-orange-500`}

  button {
    ${tw`bg-orange-600 hover:bg-orange-700`}
  }
`;

export const EligibilityMessageBox = styled(Box)`
  ${tw`bg-red-500`}

  button {
    ${tw`bg-red-600 hover:bg-red-700`}
  }
`;
