import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FormEventHandler, useEffect, useRef, useState } from "react";

import Button from "../../../common/form/Button";
import { PaymentFormProps } from "./types";

interface StripePaymentFormProps extends PaymentFormProps {
  returnUrl: string;
  createSetupIntent: () => Promise<string>;
  fullWidth?: boolean;
}

const StripePaymentForm: React.FunctionComponent<StripePaymentFormProps> = ({
  returnUrl,
  createSetupIntent,
  onReady,
  onComplete,
  onCancel,
  fullWidth = false,
  allowCancel = true,
  submitLabel,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];

      window.parent.postMessage(
        {
          source: "payment",
          message: "resize",
          payload: {
            height: entry.target.getBoundingClientRect().height,
          },
        },
        "*"
      );
    });

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    setErrorMsg(null);

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setIsSubmitting(false);
      return;
    }

    const clientSecret = await createSetupIntent();

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: "if_required",
    });

    if (error || typeof setupIntent.payment_method !== "string") {
      setErrorMsg(error?.message || "Something went wrong with your request.");
      setIsSubmitting(false);
      return;
    }

    const { success, errorMessage } = await onComplete(
      setupIntent.payment_method
    );
    if (!success) {
      setErrorMsg(errorMessage || "Something went wrong with your request.");
    }

    setIsSubmitting(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ width: fullWidth ? "100%" : "auto" }}
    >
      {errorMsg && <div tw="mb-6 text-red">{errorMsg}</div>}
      <PaymentElement
        onReady={(e) => {
          onReady && onReady();
          e.focus();
        }}
      />
      <div tw="mt-6">
        <Button
          buttonType="secondary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          {submitLabel ?? "Submit"}
        </Button>
        {!!allowCancel && (
          <Button
            type="button"
            disabled={isSubmitting || !allowCancel}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

export default StripePaymentForm;
