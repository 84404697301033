import { faAngleDown, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import tw, { styled } from "twin.macro";

import { OfferPlatformEcommerceProductsQuery } from "../../../../__generated__/graphql";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import IdDropdown from "../../../../common/IdDropdown";
import { FreeGiftOfferFormValues } from "../types";
import FreeGiftVariantsFormPartial from "./FreeGiftVariantsFormPartial";

interface FreeGiftDetailsFormPartialProps {
  mode: FormMode;
  isSubmitting: boolean;
  initialValues?: TranslatedForms<FreeGiftOfferFormValues>;
  products:
    | OfferPlatformEcommerceProductsQuery["platform_ecommerce_product"]
    | undefined;
  onChange: (values: TranslatedForms<FreeGiftOfferFormValues>) => void;
}

const ProductsWrapper = styled.div`
  display: flex;
  padding: 0.875rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.9375rem;
  align-self: stretch;
`;

const ProductWrapper = styled.div<{
  collapsed: boolean;
}>`
  ${tw`bg-gray-50 border border-gray-100`}
  display: flex;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => (props.collapsed ? "0" : "0.625rem")};
  align-self: stretch;
  border-radius: 0.375rem;
  box-shadow: 0px 2px 2px 0px rgba(177, 177, 177, 0.1);
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
`;

const ProductTitle = styled.div`
  flex: 1 0 0;
  color: var(--Gray-1, #333);
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
`;

const CollapsibleProduct = styled.div<{
  collapsed: boolean;
}>`
  display: ${(props) => (props.collapsed ? "none" : "block")};
  width: 100%;
`;

const CollapsibleInner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.625rem;
`;

const FreeGiftDetailsFormPartial: React.FunctionComponent<
  FreeGiftDetailsFormPartialProps
> = ({ mode, isSubmitting, initialValues, products, onChange }) => {
  const { register, watch, control, setValue, defaultLanguage, forms } =
    useTranslatableFormContext<FreeGiftOfferFormValues>();

  const productId = watch("productId");

  const [collapsedState, setCollapsedState] = useState<boolean>(
    mode === "edit"
  );

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  const getVariants = (productId: string) => {
    const x = products?.find((product) => product.platform_id === productId);
    return x;
  };

  return (
    <FieldSet
      title="Free gift details"
      disabled={isSubmitting || mode === "edit"}
    >
      <fieldset disabled={mode === "edit"}>
        <>
          <ProductsWrapper>
            <ProductWrapper collapsed={collapsedState}>
              {/* header */}
              <ProductHeader
                onClick={() => {
                  setCollapsedState(!collapsedState);
                }}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={collapsedState ? faAngleRight : faAngleDown}
                  transform={{ y: 5 }}
                  tw="w-4"
                />
                <ProductTitle>Product</ProductTitle>
              </ProductHeader>
              <CollapsibleProduct collapsed={collapsedState}>
                <CollapsibleInner>
                  <div tw="bg-white">
                    <Controller
                      control={control}
                      {...register("productId")}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <IdDropdown
                          displayImages={true}
                          ids={(products || []).map((plan) => ({
                            id: plan.platform_id,
                            label: plan.name,
                            img: plan.image_url || undefined,
                          }))}
                          value={field.value || ""}
                          onChange={(v) => {
                            const matchingProduct = products?.find(
                              (p) => p.platform_id === v
                            );
                            setValue(
                              "productId",
                              matchingProduct?.platform_id || null
                            );
                            setValue(
                              "productName",
                              matchingProduct?.name || null
                            );
                            setValue(
                              "productImageUrl",
                              matchingProduct?.image_url || null
                            );
                            setValue(
                              "variantId",
                              matchingProduct?.platform_variants[0]
                                ?.platform_id || null
                            );
                            setValue(
                              "variantName",
                              matchingProduct?.platform_variants[0]?.name ||
                                null
                            );

                            field.onChange(v);
                          }}
                          width="100%"
                          placeholder="Select a product…"
                          tw="h-[3.375rem] bg-white"
                        />
                      )}
                    />
                  </div>

                  {/* variants */}
                  {(getVariants(productId || "")?.platform_variants?.length ||
                    0) > 1 && (
                    <FreeGiftVariantsFormPartial
                      key={productId || ""}
                      mode={mode}
                      product={
                        products
                          ? products.find(
                              (product) => product.platform_id === productId
                            ) || null
                          : null
                      }
                      initialValue={
                        initialValues
                          ? initialValues[defaultLanguage]?.variantId || null
                          : null
                      }
                      register={register}
                      setValue={setValue}
                    />
                  )}
                </CollapsibleInner>
              </CollapsibleProduct>
            </ProductWrapper>
          </ProductsWrapper>
        </>
      </fieldset>
    </FieldSet>
  );
};

export default FreeGiftDetailsFormPartial;
