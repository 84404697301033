import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import {
  PaymentCreateStripeSetupIntentMutation,
  PaymentCreateStripeSetupIntentMutationVariables,
  PaymentSetPaymentMethodMutation,
  PaymentSetPaymentMethodMutationVariables,
  SubscriberCampaignPaymentQuery,
  SubscriberCampaignPaymentQueryVariables,
} from "../../../__generated__/graphql";
import Spinner from "../../../common/Spinner";
import useQueryParams from "../../../common/useQueryParams";
import DefaultStyles from "../flow/DefaultStyles";
import ChargebeePayment from "../payment/ChargebeePayment";
import RecurlyPayment from "../payment/RecurlyPayment";
import StripePayment from "../payment/StripePayment";
import { PaymentFormOnCompleteResult } from "../payment/types";

const Payment: React.FunctionComponent = () => {
  const { token } = useParams<{
    token: string;
  }>();

  const queryParams = useQueryParams();
  const returnUrl = queryParams.get("return_url") || "";

  const containerRef = useRef<HTMLDivElement>(null);

  const { data, loading } = useQuery<
    SubscriberCampaignPaymentQuery,
    SubscriberCampaignPaymentQueryVariables
  >(
    gql`
      query SubscriberCampaignPaymentQuery(
        $input: SubscriberCampaignPaymentInfoInput!
      ) {
        subscriberCampaignPaymentInfo(input: $input) {
          platform
          platformId
          publishableKey
          addressRequirements
        }
      }
    `,
    {
      variables: {
        input: {
          subscriberCampaignToken: token,
        },
      },
    }
  );

  const [setPaymentMethod] = useMutation<
    PaymentSetPaymentMethodMutation,
    PaymentSetPaymentMethodMutationVariables
  >(gql`
    mutation PaymentSetPaymentMethodMutation(
      $input: SetSubscriberCampaignPaymentMethodInput!
    ) {
      setSubscriberCampaignPaymentMethod(input: $input) {
        success
        errorMessage
      }
    }
  `);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];

      window.parent.postMessage(
        {
          source: "payment",
          message: "resize",
          payload: {
            height: entry.target.getBoundingClientRect().height,
          },
        },
        "*"
      );
    });

    observer.observe(document.getElementById("root")!);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleReady = () => {
    window.parent.postMessage(
      {
        source: "payment",
        message: "ready",
      },
      "*"
    );
  };

  const [createStripeSetupIntent] = useMutation<
    PaymentCreateStripeSetupIntentMutation,
    PaymentCreateStripeSetupIntentMutationVariables
  >(gql`
    mutation PaymentCreateStripeSetupIntentMutation(
      $input: CreateSubscriberCampaignStripeSetupIntentInput!
    ) {
      createSubscriberCampaignStripeSetupIntent(input: $input) {
        clientSecret
      }
    }
  `);

  const handleCreateStripeSetupIntent = async () => {
    const result = await createStripeSetupIntent({
      variables: {
        input: {
          subscriberCampaignToken: token,
        },
      },
    });

    if (!result.data?.createSubscriberCampaignStripeSetupIntent.clientSecret) {
      throw new Error();
    }

    return result.data.createSubscriberCampaignStripeSetupIntent.clientSecret;
  };

  const handleComplete = async (
    paymentMethodId: string
  ): Promise<PaymentFormOnCompleteResult> => {
    const result = await setPaymentMethod({
      variables: {
        input: {
          subscriberCampaignToken: token,
          paymentMethodId,
        },
      },
    });

    const mutationResult = result.data?.setSubscriberCampaignPaymentMethod;

    let errorMessage;

    if (!mutationResult?.success) {
      errorMessage =
        mutationResult?.errorMessage ??
        "Something went wrong with your request.";
    } else {
      window.parent.postMessage(
        {
          source: "payment",
          message: "complete",
        },
        "*"
      );
    }

    return {
      success: !!mutationResult?.success,
      errorMessage,
    };
  };

  const handleCancel = () => {
    window.parent.postMessage(
      {
        source: "payment",
        message: "closed",
      },
      "*"
    );
  };

  return (
    <>
      <DefaultStyles
        isEditMode={false}
        modal={true}
        fullScreen={false}
        showOfferTimer={false}
      />
      <div tw="p-6 bg-white rounded shadow-lg" ref={containerRef}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <h3 tw="font-title font-semibold text-lg mb-6">
              Enter your payment details to activate your offer
            </h3>
            {data?.subscriberCampaignPaymentInfo.platform === "stripe" ? (
              <StripePayment
                createSetupIntent={handleCreateStripeSetupIntent}
                publishableKey={
                  data.subscriberCampaignPaymentInfo.publishableKey
                }
                returnUrl={returnUrl}
                onReady={handleReady}
                onComplete={handleComplete}
                onCancel={handleCancel}
              />
            ) : data?.subscriberCampaignPaymentInfo.platform === "recurly" ? (
              <RecurlyPayment
                publicKey={data.subscriberCampaignPaymentInfo.publishableKey}
                addressRequirements={
                  data.subscriberCampaignPaymentInfo.addressRequirements || ""
                }
                onReady={handleReady}
                onComplete={handleComplete}
                onCancel={handleCancel}
              />
            ) : data?.subscriberCampaignPaymentInfo.platform === "chargebee" ? (
              <ChargebeePayment
                site={data.subscriberCampaignPaymentInfo.platformId}
                publishableKey={
                  data.subscriberCampaignPaymentInfo.publishableKey
                }
                onReady={handleReady}
                onComplete={handleComplete}
                onCancel={handleCancel}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default Payment;
