import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import {
  DowngradeOfferFormQuery,
  platform_enum,
} from "../../../../__generated__/graphql";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import SelectInput from "../../../../common/form/input/SelectInput";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import IdDropdown from "../../../../common/IdDropdown";
import PillRadio from "../../../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import { DowngradeOfferFormValues } from "../types";

interface DowngradeDetailsFormPartialProps {
  mode: FormMode;
  platform: platform_enum;
  onChange: (values: TranslatedForms<DowngradeOfferFormValues>) => void;
  initialValues?: TranslatedForms<DowngradeOfferFormValues>;
  isSubmitting: boolean;
}

const DowngradeDetailsFormPartial: React.FunctionComponent<
  DowngradeDetailsFormPartialProps
> = ({ mode, platform, onChange, initialValues, isSubmitting }) => {
  const { watch, formState, control, forms, setValue } =
    useTranslatableFormContext<DowngradeOfferFormValues>();

  const { data } = useQuery<DowngradeOfferFormQuery>(gql`
    query DowngradeOfferFormQuery {
      platform_plan(
        where: {
          _and: [
            { paper_code: { _is_null: false } }
            { platform_id: { _neq: "0" } }
          ]
        }
      ) {
        platform_id
        name
        paper_code
        code
        platform_product {
          name
        }
        platform_connection {
          platform
        }
        platform_plan_group {
          platform_id
          name
          code
        }
      }

      platform_connection {
        naviga_paper_codes {
          paper_code
        }
      }
    }
  `);

  const [paperCode, setPaperCode] = useState<string>("");

  const values = watch();

  useEffect(() => {
    if (
      mode === "create" &&
      data?.platform_connection[0]?.naviga_paper_codes[0]?.paper_code
    ) {
      setPaperCode(
        data.platform_connection[0].naviga_paper_codes[0].paper_code
      );
    }
  }, [data?.platform_connection, mode]);

  useEffect(() => {
    if (mode === "edit") {
      const plan = data?.platform_plan.find(
        (plan) => plan.platform_id === initialValues?.en_us?.planId
      );

      if (plan?.paper_code) {
        setPaperCode(plan?.paper_code);
      }
    }
  }, [data?.platform_plan, initialValues?.en_us?.planId, mode, values.planId]);

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  useEffect(() => {
    const plan = data?.platform_plan.find(
      (plan) => plan.platform_id === values.planId
    );
    if (plan) {
      setValue("planName", plan.name);
    }
  }, [data, setValue, values.planId]);

  return (
    <>
      <FieldSet
        title="Downgrade offer details"
        disabled={isSubmitting || mode === "edit"}
      >
        {platform === "naviga" && (
          <FieldRow>
            <FieldLabel>
              <label htmlFor="planId">Paper code</label>
            </FieldLabel>
            <FieldInput>
              <SelectInput
                width="sm"
                value={paperCode}
                onChange={(e) => setPaperCode(e.currentTarget.value)}
              >
                {data?.platform_connection[0]?.naviga_paper_codes.map(
                  (code) => (
                    <option value={code.paper_code}>{code.paper_code}</option>
                  )
                )}
              </SelectInput>
            </FieldInput>
          </FieldRow>
        )}
        <FieldRow>
          <FieldLabel>
            <label htmlFor="planId">Downgrade offer</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="planId"
              rules={{ required: true }}
              render={({ field }) => (
                <IdDropdown
                  ids={(data?.platform_plan || [])
                    .filter(
                      (plan) =>
                        platform !== "naviga" || plan.paper_code === paperCode
                    )
                    .filter((plan) => plan.name !== "Unknown Plan")
                    .map((plan) => ({
                      id: plan.platform_id,
                      displayId: plan.platform_plan_group?.code || undefined,
                      label: plan.name
                        ? `${plan.name} (${plan.platform_id})`
                        : plan.platform_id,
                    }))}
                  value={field.value}
                  onChange={field.onChange}
                  width="260px"
                  placeholder="Select a downgrade offer…"
                  fieldError={formState.errors.planId}
                />
              )}
            />
            {!platformHasFeature(
              platform,
              PlatformFeature.DowngradeAtEndOfPeriod
            ) && (
              <FieldHint>
                Subscriber will be downgraded immediately upon accepting the
                offer.
              </FieldHint>
            )}
          </FieldInput>
        </FieldRow>
      </FieldSet>
      {platformHasFeature(platform, PlatformFeature.DowngradeAtEndOfPeriod) && (
        <FieldRow>
          <FieldLabel>
            <label>Downgrade at</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="changeAt"
              render={({ field }) => (
                <PillRadio
                  value={field.value}
                  options={[
                    {
                      label: "Immediately",
                      value: "immediately",
                      tooltip: "Downgrade immediately",
                    },
                    {
                      label: "End of period",
                      value: "period_end",
                      tooltip: "Downgrade at the end of the billing period",
                    },
                  ]}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
      )}
    </>
  );
};

export default DowngradeDetailsFormPartial;
