import { ReactNode } from "react";

import { language_enum } from "../../../../__generated__/graphql";
import createIntl from "../../../../common/i18n";
import { ChangeFrequencyOfferDetails } from "../../../offers/lib/offerDetails";

export default function getChangeFrequencyDescription(
  options: ChangeFrequencyOfferDetails["options"],
  forApp: boolean = false,
  language: language_enum = language_enum.en_us,
  renderPlaceholderFn: (children: ReactNode) => ReactNode = (children) =>
    children?.toString(),
  renderHighlightFn: (children: ReactNode) => ReactNode = (children) =>
    children?.toString(),
  prefix: string = "",
  toUpper: boolean = false
) {
  const cleanPrefix = prefix ? `${prefix.trimEnd()} ` : prefix;

  const intl = createIntl(language);

  const placeholder = renderPlaceholderFn;
  const highlight = renderHighlightFn;

  if (options.length < 1) {
    return null;
  }

  if (options.length > 1) {
    if (forApp) {
      return intl.formatMessage(
        {
          defaultMessage:
            "{cleanPrefix}<highlight>a chosen frequency</highlight>",
          id: "KWXUr+",
        },
        { cleanPrefix, highlight }
      );
    }

    return intl.formatMessage(
      {
        defaultMessage:
          "{cleanPrefix}<highlight>your chosen frequency</highlight>",
        id: "5A3Xiu",
      },
      { cleanPrefix, highlight }
    );
  }

  const rawInterval = options[0].interval;
  const rawIntervalCount = options[0].intervalCount;

  const intervalCount = Number(rawIntervalCount);
  const interval = `${rawInterval}${
    intervalCount > 1 || !intervalCount ? "s" : ""
  }`;

  if (Number(intervalCount) > 1) {
    return intl.formatMessage(
      {
        defaultMessage:
          "{cleanPrefix}{firstLetter}very <highlight>{intervalCount, number} {interval}</highlight>",
        id: "ToiQX4",
      },
      {
        cleanPrefix,
        highlight,
        intervalCount,
        interval,
        firstLetter: toUpper ? "E" : "e",
      }
    );
  }

  if (Number(intervalCount) === 1) {
    return intl.formatMessage(
      {
        defaultMessage:
          "{cleanPrefix}{firstLetter}very <highlight>{interval}</highlight>",
        id: "phtNvu",
      },
      {
        cleanPrefix,
        highlight,
        interval,
        firstLetter: toUpper ? "E" : "e",
      }
    );
  }

  return intl.formatMessage(
    {
      defaultMessage:
        "{cleanPrefix}{firstLetter}very <highlight><placeholder>'<number>'</placeholder> {interval}</highlight>",
      id: "B6231d",
    },
    {
      cleanPrefix,
      highlight,
      placeholder,
      interval,
      firstLetter: toUpper ? "E" : "e",
    }
  );
}
