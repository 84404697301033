import { FlowOfferFragment } from "../../../__generated__/graphql";
import MissingImagePlaceHolder from "./ui/MissingImagePlaceholder";

interface FreeGiftProps {
  offer: FlowOfferFragment;
}

const FreeGift: React.FunctionComponent<FreeGiftProps> = ({ offer }) => {
  const placeholderVariants = ["", "-", "Default Title"];

  if (
    offer.offer_free_gift?.platform_variant?.platform_ecommerce_product?.name
  ) {
    placeholderVariants.push(
      offer.offer_free_gift.platform_variant.platform_ecommerce_product.name
    );
  }

  return (
    <div className="flow-free-gift__product">
      <div tw="flex items-center gap-[11.996px] self-stretch">
        <div className="flow-free-gift__product__img">
          {offer.offer_free_gift?.platform_variant?.platform_ecommerce_product
            ?.image_url ? (
            <img
              src={
                offer.offer_free_gift.platform_variant
                  .platform_ecommerce_product.image_url || undefined
              }
              alt=""
              tw="max-h-full max-w-full"
            />
          ) : (
            <MissingImagePlaceHolder color="blue" />
          )}
        </div>
        <div className="flow-free-gift__product__content">
          <div className="flow-free-gift__product__name">
            {
              offer.offer_free_gift?.platform_variant
                ?.platform_ecommerce_product?.name
            }
          </div>
          {!!offer.offer_free_gift?.platform_variant?.name &&
            !placeholderVariants.includes(
              offer.offer_free_gift.platform_variant.name
            ) && (
              <div className="flow-free-gift__product__variant">
                {offer.offer_free_gift?.platform_variant.name}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default FreeGift;
