import tw, { css } from "twin.macro";

interface HelpBlockProps {
  className?: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  centerIcon?: boolean;
  color?: "blue" | "gray" | "gray-flat" | "yellow-flat" | "green-flat";
  size?: "sm" | "md";
}

const HelpBlock: React.FunctionComponent<HelpBlockProps> = ({
  className,
  content,
  icon,
  centerIcon,
  color = "blue",
  size = "md",
}) => (
  <div
    className={className}
    css={[
      tw`flex p-6 rounded-xl border`,
      centerIcon && tw`items-center`,
      size === "sm" && tw`py-3 px-4 rounded-lg`,
      color === "blue"
        ? css`
            background: linear-gradient(314deg, #d1f1fb 0%, #fff 100%);
            border-color: #d5f2fc;
            box-shadow: 0px 0px 10px 0px rgba(90, 116, 147, 0.1);
          `
        : color === "gray"
        ? css`
            background: linear-gradient(0deg, #f8f9fa 0%, #f8f9fa 100%),
              linear-gradient(276deg, #56cbf2 -284.57%, #fff 108.5%);
            border-color: #eeeeee;
            box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.1);
          `
        : color === "gray-flat"
        ? css`
            ${tw`bg-gray-50 border-gray-300 text-gray-800`}
            a {
              ${tw`text-gray-700 underline hover:text-gray-800`}
            }
          `
        : color === "yellow-flat"
        ? css`
            ${tw`bg-yellow-50 border-yellow-300 text-yellow-800`}
            a {
              ${tw`text-yellow-700 underline hover:text-yellow-800`}
            }
          `
        : color === "green-flat"
        ? css`
            ${tw`bg-green-50 border-green-300 text-green-800`}
            a {
              ${tw`text-green-700 underline hover:text-green-800`}
            }
          `
        : undefined,
    ]}
  >
    {icon && <div tw="mr-3">{icon}</div>}
    <div tw="max-w-[54rem]">{content}</div>
  </div>
);

export default HelpBlock;
