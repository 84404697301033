import classNames from "classnames";
import { useEffect, useState } from "react";
import { css } from "twin.macro";

import { FlowOfferFragment } from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import { isContentEmpty } from "../../../common/editor2/lib";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import getChangeFrequencyDescription from "./lib/getChangeFrequencyDescription";
import renderHighlight from "./lib/renderHighlight";
import renderPlaceholder from "./lib/renderPlaceholder";
import OfferOption from "./ui/OfferOption";
import OfferOptionInput from "./ui/OfferOptionInput";
import OfferOptionInputItem from "./ui/OfferOptionInputItem";
import OfferOptionsFieldSet from "./ui/OfferOptionsFieldSet";
import OfferOptionsSection from "./ui/OfferOptionsSection";

interface ChangeFrequencyFormProps {
  offer: FlowOfferFragment | null;
  baseClassName: string;
  onClickChangeFrequencyOption: (index: number) => void;
}

const ChangeFrequencyForm: React.FunctionComponent<
  ChangeFrequencyFormProps
> = ({ offer, baseClassName, onClickChangeFrequencyOption }) => {
  const { language, translationValue } = useTranslations();

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  useEffect(() => {
    setSelectedOptionIndex(0);
  }, [offer?.offer_change_frequency?.offer_change_frequency_options.length]);

  const handleClickChangeFrequencyOption = (value: number) => {
    setSelectedOptionIndex(value);
    onClickChangeFrequencyOption(value);
  };

  if (!offer?.offer_change_frequency) {
    return null;
  }

  return (
    <>
      {offer.offer_change_frequency.offer_change_frequency_options.length >
        1 && (
        <OfferOptionsSection>
          <OfferOptionsFieldSet
            className={classNames([
              `${baseClassName}__pause-options-container`,
              "flow-pause-subscription__pause-options-container",
            ])}
          >
            <OfferOption>
              <OfferOptionInput
                className={classNames([
                  `${baseClassName}__pause-options`,
                  "flow-pause-subscription__pause-options",
                ])}
              >
                {offer.offer_change_frequency.offer_change_frequency_options.map(
                  (o, i) => {
                    const textTranslation = translationValue(
                      o.text_translation
                    ).value;

                    return (
                      <OfferOptionInputItem
                        type="button"
                        key={`pause-option-${i}`}
                        className={classNames({
                          [`${baseClassName}__pause-options__item`]: true,
                          active: i === selectedOptionIndex,
                        })}
                        onClick={() => handleClickChangeFrequencyOption(i)}
                      >
                        {textTranslation && !isContentEmpty(textTranslation) ? (
                          <div
                            css={css`
                              && p {
                                text-align: center;
                                cursor: pointer;
                              }
                            `}
                          >
                            <Editor
                              initialValue={JSON.stringify(textTranslation)}
                              initialValueKey={JSON.stringify(textTranslation)}
                              isReadOnly={true}
                              isInline={true}
                            />
                          </div>
                        ) : (
                          <>
                            {getChangeFrequencyDescription(
                              [
                                {
                                  interval: o.interval,
                                  intervalCount: o.interval_count,
                                },
                              ],
                              false,
                              language,
                              renderPlaceholder,
                              renderHighlight,
                              "",
                              true
                            )}
                          </>
                        )}
                      </OfferOptionInputItem>
                    );
                  }
                )}
              </OfferOptionInput>
            </OfferOption>
          </OfferOptionsFieldSet>
        </OfferOptionsSection>
      )}
    </>
  );
};

export default ChangeFrequencyForm;
