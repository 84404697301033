import { Controller } from "react-hook-form";

import {
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_goal_enum,
  offer_type_enum,
  platform_enum,
} from "../../../../__generated__/graphql";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldError from "../../../../common/form/FieldError";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FormMode from "../../../../common/form/FormMode";
import SelectInput from "../../../../common/form/input/SelectInput";
import TagsInput from "../../../../common/form/input/TagsInput";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import HelpIcon from "../../../../common/HelpIcon";
import usePaidFeature, {
  generatePaidFeatureClassName,
} from "../../../upgrade-account/usePaidFeature";
import useValidateName from "../../lib/useValidateName";
import { OfferBaseFormValues } from "../types";
import AutopilotBaseFormPartial from "./AutopilotBaseFormPartial";
import ConversionOfferTimeModePartial from "./ConversionOfferTimeModePartial";

interface OfferBaseFormProps {
  mode: FormMode;
  offerType: offer_type_enum;
  isSubmitting: boolean;
  tags: string[];
  platform: platform_enum;
  goal?: offer_goal_enum;
  strategy?: offer_autopilot_strategy_enum;
  successMetric?: offer_autopilot_success_metric_enum;
  onClickChangeType?: () => void;
}

const OfferBaseFormPartial: React.FunctionComponent<OfferBaseFormProps> = ({
  mode,
  offerType,
  isSubmitting,
  tags,
  platform,
  goal = offer_goal_enum.retention,
  strategy,
  successMetric,
  onClickChangeType,
}) => {
  const { isFreeMode, setPaidFeatureRef } = usePaidFeature();

  const { register, watch, formState, control } =
    useTranslatableFormContext<OfferBaseFormValues>();

  const validateName = useValidateName(formState.dirtyFields.name || false);
  const values = watch();

  const isAutopilot = !!strategy && !!successMetric;

  return (
    <>
      {isAutopilot && (
        <AutopilotBaseFormPartial
          mode={mode}
          offerType={offerType}
          strategy={strategy}
          successMetric={successMetric}
          onClickChangeType={onClickChangeType}
        />
      )}

      <fieldset disabled={isSubmitting} ref={(ref) => setPaidFeatureRef(ref)}>
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
            {platform !== "custom" && (
              <FieldHint>May appear on receipts and invoices.</FieldHint>
            )}
          </FieldInput>
        </FieldRow>
        <FieldRow
          className={generatePaidFeatureClassName(
            "Upgrade your plan to add custom tags.",
            isFreeMode,
            "form"
          )}
        >
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>

        {goal !== offer_goal_enum.reactivation && (
          <>
            {goal === offer_goal_enum.conversion ? (
              <>
                <FieldRow>
                  <FieldLabel>
                    <label htmlFor="style">Style</label>
                  </FieldLabel>
                  <FieldInput>
                    <SelectInput
                      {...register("style")}
                      id="style"
                      defaultValue="modal"
                    >
                      <option value="modal">Modal</option>
                      <option value="banner">Banner</option>
                    </SelectInput>
                  </FieldInput>
                </FieldRow>

                <ConversionOfferTimeModePartial />
              </>
            ) : (
              <FieldRow
                className={generatePaidFeatureClassName(
                  "Upgrade your plan to modify offer style.",
                  isFreeMode,
                  "form"
                )}
              >
                <FieldLabel>
                  <label htmlFor="style">Style</label>
                </FieldLabel>
                <FieldInput>
                  <SelectInput
                    {...register("style")}
                    id="style"
                    defaultValue="modal"
                  >
                    <option value="modal">Modal</option>
                    <option value="step">Step</option>
                  </SelectInput>
                  <FieldHint>
                    {values.style === "modal" ? (
                      <>Offer will be shown in a pop-up modal dialog.</>
                    ) : (
                      <>Offer will be shown as a full flow step.</>
                    )}
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            )}
            <FieldRow
              className={generatePaidFeatureClassName(
                "By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. Upgrade your plan to allow your offers to override previously accepted offers.",
                isFreeMode,
                "form"
              )}
            >
              <FieldLabel>
                <label>
                  Allow override{" "}
                  <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
                </label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("allowOverride")}
                  id="allowOverride"
                  type="checkbox"
                  disabled={isFreeMode}
                />
                <label htmlFor="allowOverride">Override other offers</label>
              </FieldCheckboxInput>
            </FieldRow>
          </>
        )}
      </fieldset>
    </>
  );
};

export default OfferBaseFormPartial;
