import { useEffect, useState } from "react";

import {
  language_enum,
  offer_goal_enum,
  offer_type_enum,
  OfferFormContainerCouponsQuery,
  platform_enum,
} from "../../../__generated__/graphql";
import FormMode from "../../../common/form/FormMode";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import useTranslatableFormContext from "../../../common/form/useTranslatableFormContext";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import CouponDetailsFormPartial from "./partials/CouponDetailsFormPartial";
import OfferBaseFormPartial from "./partials/OfferBaseFormPartial";
import OfferContentFormPartial from "./partials/OfferContentFormPartial";
import OfferMetadataFormPartial from "./partials/OfferMetadataFormPartial";
import { ReactivateOfferFormValues } from "./types";

interface ReactivateOfferFormProps {
  mode: FormMode;
  goal?: offer_goal_enum;
  platform: platform_enum;
  onChange: (values: TranslatedForms<ReactivateOfferFormValues>) => void;
  onSubmit: (values: TranslatedForms<ReactivateOfferFormValues>) => void;
  isSubmitting: boolean;
  coupons: OfferFormContainerCouponsQuery["platform_coupon"] | undefined;
  tags: string[];
  onChangeEditingLanguage: (value: language_enum) => void;
}

const ReactivateOfferForm: React.FunctionComponent<
  ReactivateOfferFormProps
> = ({
  mode,
  onChange,
  onSubmit,
  isSubmitting,
  coupons,
  tags,
  platform,
  goal = offer_goal_enum.reactivation,
  onChangeEditingLanguage,
}) => {
  const { register, handleSubmit, forms } =
    useTranslatableFormContext<ReactivateOfferFormValues>();

  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(mode === "create" ? formRef : null);

  useEffect(() => {
    onChange(forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  return (
    <form
      id="create-offer"
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input {...register("type")} type="hidden" value="reactivate" />

      <OfferBaseFormPartial
        mode={mode}
        isSubmitting={isSubmitting}
        offerType={offer_type_enum.reactivate}
        platform={platform}
        tags={tags}
        goal={goal}
      />

      <CouponDetailsFormPartial
        mode={mode}
        isSubmitting={isSubmitting}
        coupons={coupons}
        platform={platform}
        goal={goal}
      />

      <OfferContentFormPartial
        isSubmitting={isSubmitting}
        goal={goal}
        onChangeEditingLanguage={onChangeEditingLanguage}
        hideButtonText={true}
      />

      <OfferMetadataFormPartial isSubmitting={isSubmitting} />
    </form>
  );
};

export default ReactivateOfferForm;
