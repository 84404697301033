import { faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPresent } from "ts-is-present";
import tw from "twin.macro";

import { FlowOfferRulesQuery } from "../../../../__generated__/graphql";
import OfferRuleOffers from "./OfferRuleOffers";

interface RuleRule {
  weight: number;
  offerIds: number[];
  offerTestIds: number[];
  offerGroupIds: number[];
  offerAutopilotOfferIds: number[];
  deflectionIds: number[];
  includePresentNoOffer: boolean;
}

interface OfferRuleRulesProps {
  ruleRules: RuleRule[];
  offerRules: FlowOfferRulesQuery;
  isEditing: boolean;
}

const OfferRuleRules: React.FunctionComponent<OfferRuleRulesProps> = ({
  ruleRules,
  offerRules,
  isEditing,
}) => (
  <div>
    {ruleRules.map((r, ruleIndex) => (
      <div
        key={`rule-${ruleIndex}`}
        tw="relative mb-2 pb-2 border-b last:border-none last:pb-0 last:mb-0"
      >
        {ruleRules.length > 1 && (
          <div
            css={[
              tw`mb-2`,
              isEditing
                ? undefined
                : tw`-mt-1 bg-gray-100 text-gray-800 rounded-md inline-block px-2`,
            ]}
          >
            <FontAwesomeIcon icon={faWeightHanging} /> Weight: {r.weight}
          </div>
        )}
        <OfferRuleOffers
          rule={r}
          offers={offerRules.offer}
          offerTests={offerRules.offer_test}
          offerGroups={offerRules.offer_group}
          offerAutopilotOffers={offerRules.offer_autopilot
            .map((a) => a.offer)
            .filter(isPresent)}
        />
      </div>
    ))}
  </div>
);

export default OfferRuleRules;
