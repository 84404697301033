import { gql, useMutation, useQuery } from "@apollo/client";
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckCircle,
  faCircleExclamation,
  faCreditCardFront,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory, useParams } from "react-router-dom";

import {
  language_enum,
  ReactivateOfferAcceptSubscriberOfferReactivateMutation,
  ReactivateOfferAcceptSubscriberOfferReactivateMutationVariables,
  ReactivateOfferCreateAStripeSetupIntentMutation,
  ReactivateOfferCreateAStripeSetupIntentMutationVariables,
  ReactivateOfferInitializeMutation,
  ReactivateOfferInitializeMutationVariables,
  ReactivateOfferPaymentMethodsQuery,
  ReactivateOfferPropertyQuery,
  ReactivateOfferSetPaymentMethodMutation,
  ReactivateOfferSetPaymentMethodMutationVariables,
  SubscriberOfferReactivateFragment,
  SubscriberOfferReactivatePaymentInfo,
  SubscriberOfferReactivatePlan,
} from "../../../__generated__/graphql";
import Button from "../../../common/form/Button";
import Spinner from "../../../common/Spinner";
import TranslationsProvider from "../../../common/translations/TranslationsProvider";
import useLoading from "../../../common/useLoading";
import usePrevious from "../../../common/usePrevious";
import { PropertyValuesProvider } from "../../properties/lib/propertyValues";
import DefaultStyles from "../flow/DefaultStyles";
import getCouponDescription from "../flow/lib/getCouponDescription";
import mapFlowText from "../flow/lib/mapFlowText";
import OfferContainer from "../flow/OfferContainer";
import ChargebeePayment from "../payment/ChargebeePayment";
import RecurlyPayment from "../payment/RecurlyPayment";
import StripePayment from "../payment/StripePayment";
import { PaymentFormOnCompleteResult } from "../payment/types";
import Header from "./Header";
import PlanSummary from "./PlanSummary";

const ReactivateOffer: React.FunctionComponent = () => {
  const history = useHistory();

  const paymentMethodsScrollableRef = useRef<HTMLDivElement>(null);
  const newPaymentMethodRef = useRef<HTMLDivElement>(null);

  const { reactivateToken } = useParams<{
    reactivateToken: string;
  }>();

  const [chosenPaymentMethod, setChosenPaymentMethod] = useState<
    string | undefined
  >("");
  const [newPaymentMethodExpanded, setNewPaymentMethodExpanded] =
    useState(false);
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const [newPaymentMethodAdded, setNewPaymentMethodAdded] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);

  const [initialized, setInitialized] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [expired, setExpired] = useState(false);

  const [error, setError] = useState(false);
  const [offerAccepted, setOfferAccepted] = useState(false);

  const [sessionData, setSessionData] =
    useState<SubscriberOfferReactivateFragment>();
  const [planData, setPlanData] = useState<SubscriberOfferReactivatePlan>();
  const [paymentData, setPaymentData] =
    useState<SubscriberOfferReactivatePaymentInfo>();

  const [initializeSession, initializeSessionData] = useMutation<
    ReactivateOfferInitializeMutation,
    ReactivateOfferInitializeMutationVariables
  >(gql`
    mutation ReactivateOfferInitializeMutation($token: String!) {
      subscriberOfferReactivate(input: { token: $token }) {
        success
        status
        account {
          title
          faviconUrl
          logoUrl
        }
        subscriberOfferReactivateData
        plan {
          platformId
          name
          amount
          currency
        }
        paymentInfo {
          platform
          platformId
          publishableKey
          addressRequirements
        }
      }
    }
  `);
  const [setPaymentMethod] = useMutation<
    ReactivateOfferSetPaymentMethodMutation,
    ReactivateOfferSetPaymentMethodMutationVariables
  >(gql`
    mutation ReactivateOfferSetPaymentMethodMutation(
      $input: SetSubscriberOfferReactivatePaymentMethodInput!
    ) {
      setSubscriberOfferReactivatePaymentMethod(input: $input) {
        success
        errorMessage
      }
    }
  `);

  useEffect(() => {
    if (initialized) {
      return;
    }

    (async () => {
      const result = await initializeSession({
        variables: { token: reactivateToken },
      });

      if (
        !result.data?.subscriberOfferReactivate?.success ||
        !result.data?.subscriberOfferReactivate?.status
      ) {
        history.replace("/not-found");
      }

      const status = result.data?.subscriberOfferReactivate.status;
      switch (status) {
        case "accepted":
          setAccepted(true);
          setInitialized(true);
          return;

        case "expired":
          setExpired(true);
          setInitialized(true);
          return;
      }

      if (
        result.data?.subscriberOfferReactivate.subscriberOfferReactivateData
      ) {
        setSessionData(
          JSON.parse(
            result.data.subscriberOfferReactivate.subscriberOfferReactivateData
          ) as SubscriberOfferReactivateFragment
        );
      }

      if (result.data?.subscriberOfferReactivate?.plan) {
        setPlanData(result.data.subscriberOfferReactivate.plan);
      }

      if (result.data?.subscriberOfferReactivate?.paymentInfo) {
        setPaymentData(result.data.subscriberOfferReactivate.paymentInfo);
      }

      setInitialized(true);
    })();
  }, [initialized, initializeSession, reactivateToken, history]);

  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    refetch: paymentMethodsRefetch,
  } = useQuery<ReactivateOfferPaymentMethodsQuery>(
    gql`
      query ReactivateOfferPaymentMethodsQuery($token: String!) {
        reactivationPaymentMethods(
          input: { subscriberOfferReactivateToken: $token }
        ) {
          paymentMethods {
            id
            name
            type
            brand
            lastFour
            expireMonth
            expireYear
          }
        }
      }
    `,
    {
      variables: {
        token: reactivateToken,
      },
    }
  );

  const { data: propertyData, loading: propertyDataLoading } =
    useQuery<ReactivateOfferPropertyQuery>(
      gql`
        query ReactivateOfferPropertyQuery {
          property(where: { deleted_at: { _is_null: true } }) {
            id
            name
            type
            format
          }
        }
      `
    );

  const existingPaymentMethods =
    paymentMethodsData?.reactivationPaymentMethods.paymentMethods;

  const previousPaymentMethods = usePrevious(existingPaymentMethods);

  useEffect(() => {
    if (
      newPaymentMethodAdded &&
      existingPaymentMethods?.length &&
      JSON.stringify(existingPaymentMethods) !==
        JSON.stringify(previousPaymentMethods)
    ) {
      const newMethodId = existingPaymentMethods.find(
        (pm) =>
          !(previousPaymentMethods || []).map((prev) => prev.id).includes(pm.id)
      )?.id;

      setChosenPaymentMethod(newMethodId || undefined);
      setNewPaymentMethodAdded(false);
      return;
    }

    setChosenPaymentMethod(
      existingPaymentMethods
        ? existingPaymentMethods[0]?.id || undefined
        : undefined
    );
  }, [existingPaymentMethods, newPaymentMethodAdded, previousPaymentMethods]);

  useEffect(() => {
    setNewPaymentMethodExpanded(!chosenPaymentMethod);
  }, [chosenPaymentMethod, paymentMethodsLoading]);

  const propertyConfig = (propertyData?.property || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  const offer = sessionData?.offer;

  const description = getCouponDescription(
    offer?.offer_reactivate?.type,
    Number(offer?.offer_reactivate?.amount_off),
    offer?.offer_reactivate?.duration,
    offer?.offer_reactivate?.duration_interval,
    offer?.offer_reactivate?.duration_count,
    offer?.offer_reactivate?.months,
    undefined,
    language_enum.en_us,
    true
  );

  const baseCost = offer?.offer_reactivate?.amount_off
    ? planData?.amount || 0
    : null;
  const cost = (() => {
    const planAmount = planData?.amount || 0;

    if (!offer?.offer_reactivate?.amount_off) {
      return planAmount;
    }

    if (offer?.offer_reactivate.type === "fixed") {
      return Math.max(
        planAmount - Number(offer?.offer_reactivate?.amount_off),
        0
      );
    }

    if (offer?.offer_reactivate.type === "percentage") {
      return (
        planAmount * ((100 - Number(offer?.offer_reactivate?.amount_off)) / 100)
      );
    }

    return planAmount;
  })();

  const account =
    initializeSessionData.data?.subscriberOfferReactivate?.account;

  const logoUrl = account?.logoUrl;

  const handleComplete = async (
    paymentMethodId: string
  ): Promise<PaymentFormOnCompleteResult> => {
    const result = await setPaymentMethod({
      variables: {
        input: {
          subscriberOfferReactivateToken: reactivateToken,
          paymentMethodId,
        },
      },
    });

    const mutationResult =
      result.data?.setSubscriberOfferReactivatePaymentMethod;

    let errorMessage;

    if (!mutationResult?.success) {
      errorMessage =
        mutationResult?.errorMessage ??
        "Something went wrong with your request.";
    }

    setNewPaymentMethodAdded(true);
    await paymentMethodsRefetch();
    setNewPaymentMethodExpanded(false);

    return {
      success: true,
      errorMessage,
    };
  };

  const [createStripeSetupIntent] = useMutation<
    ReactivateOfferCreateAStripeSetupIntentMutation,
    ReactivateOfferCreateAStripeSetupIntentMutationVariables
  >(gql`
    mutation ReactivateOfferCreateAStripeSetupIntentMutation(
      $input: CreateSubscriberOfferReactivateStripeSetupIntentInput!
    ) {
      createSubscriberOfferReactivateStripeSetupIntent(input: $input) {
        clientSecret
      }
    }
  `);

  const handleCreateStripeSetupIntent = async () => {
    const result = await createStripeSetupIntent({
      variables: {
        input: {
          subscriberOfferReactivateToken: reactivateToken,
        },
      },
    });

    if (
      !result.data?.createSubscriberOfferReactivateStripeSetupIntent
        .clientSecret
    ) {
      throw new Error();
    }

    return result.data.createSubscriberOfferReactivateStripeSetupIntent
      .clientSecret;
  };

  const handleScroll = useCallback(() => {
    if (!newPaymentMethodRef.current) {
      return;
    }

    newPaymentMethodRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const handleReady = () => {
    window.parent.postMessage(
      {
        source: "payment",
        message: "ready",
      },
      "*"
    );
    setPaymentFormLoading(false);
    setTimeout(handleScroll, 333);
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenPaymentMethod(e.target.value);
    setNewPaymentMethodExpanded(e.target.value === "");
    e.target.value === "" && setPaymentFormLoading(true);
  };

  const [acceptSubscriberOfferReactivate] = useMutation<
    ReactivateOfferAcceptSubscriberOfferReactivateMutation,
    ReactivateOfferAcceptSubscriberOfferReactivateMutationVariables
  >(gql`
    mutation ReactivateOfferAcceptSubscriberOfferReactivateMutation(
      $input: AcceptSubscriberOfferReactivateInput!
    ) {
      acceptSubscriberOfferReactivate(input: $input) {
        success
        error
      }
    }
  `);

  const handleSubmit = async () => {
    if (!planData || !chosenPaymentMethod) {
      return;
    }

    setSubmitting(true);

    const acceptResult = await acceptSubscriberOfferReactivate({
      variables: {
        input: {
          subscriberOfferReactivateToken: reactivateToken,
          planId: planData.platformId,
          paymentMethodId: chosenPaymentMethod,
        },
      },
    });

    if (acceptResult.data?.acceptSubscriberOfferReactivate.success) {
      setAccepted(true);
    } else {
      setError(true);
    }

    setSubmitting(false);
  };

  const cardIcon = (brand: string) => {
    switch (brand) {
      case "american_express":
        return <FontAwesomeIcon icon={faCcAmex} />;

      case "diners_club":
        return <FontAwesomeIcon icon={faCcDinersClub} />;

      case "discover":
        return <FontAwesomeIcon icon={faCcDiscover} />;

      case "jcb":
        return <FontAwesomeIcon icon={faCcJcb} />;

      case "mastercard":
        return <FontAwesomeIcon icon={faCcMastercard} />;

      case "visa":
        return <FontAwesomeIcon icon={faCcVisa} />;

      default:
        return <FontAwesomeIcon icon={faCreditCardFront} />;
    }
  };

  const loading = useLoading(
    propertyDataLoading || paymentMethodsLoading || !initialized
  );

  const basePaymentFormProps = {
    onReady: handleReady,
    onComplete: handleComplete,
    onCancel: () => {},
    allowCancel: false,
    submitLabel: "Save",
  };

  const isLoading = useLoading(
    loading || paymentMethodsLoading || accepted === undefined
  );

  return (
    <>
      <DefaultStyles
        isEditMode={false}
        modal={false}
        fullScreen={true}
        showOfferTimer={false}
      />
      {isLoading ? (
        <div className="reactivate">
          <Spinner />
        </div>
      ) : accepted ? (
        <div className="reactivate">
          <div
            className={classNames([
              "reactivate-offer-panel",
              "reactivate-offer-panel--accepted",
            ])}
          >
            <div>
              <Helmet>
                <title>{account?.title && `Billing - ${account.title}`}</title>
                {account?.faviconUrl && (
                  <link rel="icon" href={account.faviconUrl}></link>
                )}
              </Helmet>
              <Header logoUrl={logoUrl} companyName={account?.title} />
            </div>
            <div className="reactivate-success">
              <div className="reactivate-success__check">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="reactivate-success__check__icon"
                />
              </div>
              <div className="reactivate-success__text">
                <div className="reactivate-success__text__header">
                  <span>Offer accepted</span>
                </div>
                <div className="reactivate-success__text__body">
                  <span>Your offer has been accepted.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : expired ? (
        <div className="reactivate">
          <div
            className={classNames([
              "reactivate-offer-panel",
              "reactivate-offer-panel--expired",
            ])}
          >
            <div>
              <Helmet>
                <title>{account?.title && `Billing - ${account.title}`}</title>
                {account?.faviconUrl && (
                  <link rel="icon" href={account.faviconUrl}></link>
                )}
              </Helmet>
              <Header logoUrl={logoUrl} companyName={account?.title} />
            </div>
            <div className="reactivate-expired">
              <div className="reactivate-expired__content">
                <div className="reactivate-expired__exclamation">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="reactivate-expired__exclamation__icon"
                  />
                </div>
                <div className="reactivate-expired__text">
                  <div className="reactivate-expired__text__header">
                    <span>This offer has expired</span>
                  </div>
                  <div className="reactivate-expired__text__body">
                    <span>
                      The offer you're looking for is no longer available.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="reactivate">
          <div
            className={classNames({
              "reactivate-offer-panel": true,
              "reactivate-offer-panel--mobile-hidden": offerAccepted,
            })}
          >
            <div
              className={classNames({
                "reactivate-offer-panel__header": true,
              })}
            >
              <Helmet>
                <title>
                  {!!account?.title && `Billing - ${account.title}`}
                </title>
                {!!account?.faviconUrl && (
                  <link rel="icon" href={account.faviconUrl}></link>
                )}
              </Helmet>
              <Header logoUrl={logoUrl} companyName={account?.title} />
            </div>

            {!!offer && (
              <div className="reactivate-offer">
                <TranslationsProvider
                  language={language_enum.en_us}
                  defaultLanguage={language_enum.en_us}
                  enabledLanguages={[language_enum.en_us]}
                >
                  <PropertyValuesProvider
                    propertyValues={{}}
                    propertyConfig={propertyConfig}
                    showPlaceholders={true}
                  >
                    <div
                      className={classNames({
                        "flow-modal__container": true,
                        "flow-modal__container--reactivate": !offerAccepted,
                      })}
                      tw="mt-[-100px]"
                    >
                      <OfferContainer
                        offer={offer}
                        previewMode={false}
                        baseClassName="flow-modal"
                        disableVideoAutoPlay={false}
                        onImageLoad={() => {}}
                        flowText={mapFlowText([], "published")}
                        onAccept={() => {
                          setOfferAccepted(true);
                        }}
                        onAcceptGroupOffer={() => {}}
                        onDecline={() => {}}
                        isFinalStep={false}
                        showCloseX={false}
                        showButtons={false}
                        planSummary={{
                          plan: planData,
                          description,
                          baseCost,
                          cost,
                        }}
                      />
                    </div>
                  </PropertyValuesProvider>
                </TranslationsProvider>
              </div>
            )}
          </div>
          <div
            className={classNames({
              "reactivate-payment-panel": true,
              "reactivate-payment-panel--mobile-hidden": !offerAccepted,
            })}
          >
            <div className="reactivate-payment-panel__header">
              <span>Confirm offer</span>
            </div>
            <div className="reactivate-payment-panel__body">
              {!!error && (
                <div className="reactivate-payment-error">
                  <div className="reactivate-payment-error__icon">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      tw="text-red-500"
                    />
                  </div>
                  <div className="reactivate-payment-error__body">
                    <span>
                      An unknown error has occurred. Please try again later.
                    </span>
                  </div>
                </div>
              )}
              <div className="reactivate-payment-plan">
                <span className="reactivate-payment-plan__header">Plan</span>
                <PlanSummary
                  plan={planData}
                  description={description}
                  baseCost={baseCost}
                  cost={cost}
                />
              </div>
              <div className="reactivate-payment-methods">
                <span className="reactivate-payment-methods__header">
                  Payment Information
                </span>
                <div
                  ref={paymentMethodsScrollableRef}
                  className="reactivate-payment-methods__body"
                >
                  {existingPaymentMethods?.map((pm) => (
                    <div
                      className={classNames({
                        "reactivate-payment-methods__item": true,
                        "reactivate-payment-methods__item--existing": true,
                        "reactivate-payment-methods__item--selected":
                          chosenPaymentMethod === pm.id,
                      })}
                      key={pm.id}
                      onClick={() => {
                        if (pm.id && chosenPaymentMethod !== pm.id) {
                          setChosenPaymentMethod(pm.id);
                          setNewPaymentMethodExpanded(false);
                        }
                      }}
                    >
                      <div className="reactivate-payment-methods__details">
                        <div className="reactivate-payment-methods__card">
                          {cardIcon(pm.brand || "")}
                          <span>···· {pm.lastFour}</span>
                        </div>
                        <div className="reactivate-payment-methods__card-secondary">
                          <span>
                            {pm.name || "Customer"} ·{" "}
                            {pm.expireMonth?.toString().padStart(2, "0")}/
                            {pm.expireYear}
                          </span>
                        </div>
                      </div>

                      <input
                        checked={chosenPaymentMethod === pm.id}
                        onChange={handleRadio}
                        type="radio"
                        name="paymentMethod"
                        value={pm.id || undefined}
                      />
                    </div>
                  ))}

                  <div
                    ref={newPaymentMethodRef}
                    className={classNames({
                      "reactivate-payment-methods__item": true,
                      "reactivate-payment-methods__item--new": true,
                      "reactivate-payment-methods__item--selected":
                        !chosenPaymentMethod,
                    })}
                    onClick={() => {
                      if (chosenPaymentMethod !== undefined) {
                        setChosenPaymentMethod(undefined);
                        setNewPaymentMethodExpanded(true);
                        setPaymentFormLoading(true);
                      }
                    }}
                  >
                    <div
                      className={classNames({
                        "reactivate-payment-methods__details-wrapper": true,
                        "reactivate-payment-methods__details-wrapper--open":
                          newPaymentMethodExpanded,
                      })}
                    >
                      <span>
                        {!!existingPaymentMethods?.length
                          ? "Use a different method"
                          : "New payment method"}
                      </span>
                      <input
                        checked={!chosenPaymentMethod}
                        onChange={handleRadio}
                        type="radio"
                        name="paymentMethod"
                        value={undefined}
                      />
                    </div>

                    {newPaymentMethodExpanded ? (
                      <>
                        {!!paymentFormLoading && <Spinner />}
                        <div
                          className={classNames({
                            "reactivate-payment-form": true,
                            "reactivate-payment-form--hidden":
                              paymentFormLoading,
                          })}
                        >
                          <>
                            {paymentData?.platform === "stripe" ? (
                              <StripePayment
                                {...basePaymentFormProps}
                                createSetupIntent={
                                  handleCreateStripeSetupIntent
                                }
                                publishableKey={paymentData.publishableKey}
                                returnUrl={window.location.href}
                                fullWidth={true}
                              />
                            ) : paymentData?.platform === "chargebee" ? (
                              <ChargebeePayment
                                {...basePaymentFormProps}
                                site={paymentData.platformId}
                                publishableKey={paymentData.publishableKey}
                              />
                            ) : paymentData?.platform === "recurly" ? (
                              <RecurlyPayment
                                {...basePaymentFormProps}
                                publicKey={paymentData.publishableKey}
                                addressRequirements={
                                  paymentData.addressRequirements || ""
                                }
                              />
                            ) : null}
                          </>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="reactivate-payment-panel__footer">
              <div className="reactivate-payment-summary">
                <div className="reactivate-payment-summary__item">
                  <span className="reactivate-payment-summary__item__label">
                    Subscription
                  </span>
                  <span className="reactivate-payment-summary__item__value">
                    <span className="reactivate-payment-summary__item__value--bold">
                      {planData?.name}
                    </span>
                  </span>
                </div>
                <div className="reactivate-payment-summary__item">
                  <span className="reactivate-payment-summary__item__label">
                    Subtotal
                  </span>
                  <span className="reactivate-payment-summary__item__value">
                    <span className="reactivate-payment-summary__item__value--bold">
                      {baseCost
                        ? Intl.NumberFormat(
                            navigator.languages
                              ? [...navigator.languages]
                              : "en-US",
                            {
                              style: "currency",
                              currency: planData?.currency || "usd",
                            }
                          ).format(baseCost || 0)
                        : Intl.NumberFormat(
                            navigator.languages
                              ? [...navigator.languages]
                              : "en-US",
                            {
                              style: "currency",
                              currency: planData?.currency || "usd",
                            }
                          ).format(cost || 0)}
                    </span>
                    <span> /month</span>
                  </span>
                </div>
                <div className="reactivate-payment-summary__item">
                  <span className="reactivate-payment-summary__item__label">
                    Discount
                  </span>
                  <span className="reactivate-payment-summary__item__value">
                    {!!baseCost && (
                      <>
                        <span className="reactivate-payment-summary__item__value--bold">
                          ({description}) -
                          {Intl.NumberFormat(
                            navigator.languages
                              ? [...navigator.languages]
                              : "en-US",
                            {
                              style: "currency",
                              currency: planData?.currency || "usd",
                            }
                          ).format(baseCost - cost)}
                        </span>
                        <span> /month</span>
                      </>
                    )}
                  </span>
                </div>
                <div className="reactivate-payment-summary__item">
                  <span className="reactivate-payment-summary__item__label">
                    What you'll pay
                  </span>
                  <span className="reactivate-payment-summary__item__value">
                    <span className="reactivate-payment-summary__item__value--bold">
                      {Intl.NumberFormat(
                        navigator.languages
                          ? [...navigator.languages]
                          : "en-US",
                        {
                          style: "currency",
                          currency: planData?.currency || "usd",
                        }
                      ).format(cost)}
                    </span>
                    <span> /month</span>
                  </span>
                </div>
              </div>
              <div className="reactivate-payment-actions">
                <div className="reactivate-payment-actions__buttons">
                  <Button
                    buttonType="neutral"
                    onClick={handleSubmit}
                    disabled={!chosenPaymentMethod || submitting}
                    isLoading={submitting}
                    className="reactivate-payment-actions__submit"
                  >
                    <span
                      className={classNames([
                        "reactivate-payment-actions__submit-label",
                        "reactivate-payment-actions__submit-label--desktop",
                      ])}
                    >
                      Accept
                    </span>
                    <span
                      className={classNames([
                        "reactivate-payment-actions__submit-label",
                        "reactivate-payment-actions__submit-label--mobile",
                      ])}
                    >
                      Confirm
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReactivateOffer;
