import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { isPresent } from "ts-is-present";
import { css } from "twin.macro";

import Button from "../form/Button";
import SearchInput from "../search/SearchInput";
import StandardLinkButton from "../StandardLinkButton";
import Expander from "./Expander";
import { AnyBox, Box, BoxContainer } from "./RuleBoxes";

export interface RuleExpanderItem {
  id: number;
  label: React.ReactNode;
  search?: string;
  group?: string;
  color?: string;
  disabled?: boolean;
}
interface RuleExpanderProps {
  title?: string;
  className?: string;
  items: RuleExpanderItem[];
  itemColor: string;
  value: number[];
  itemName: string;
  search?: boolean;
  selectAll?: boolean;
  includeAny?: boolean;
  onChange: (value: number[]) => void;
  createButtons?: Array<
    | {
        label: string;
        disabledMessage?: string;
        disabled?: boolean;
        onClick: () => void;
      }
    | undefined
  >;
}

const RuleExpander: React.FunctionComponent<RuleExpanderProps> = ({
  title,
  className,
  items,
  itemColor,
  value,
  itemName,
  search,
  selectAll,
  includeAny = false,
  createButtons,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<RuleExpanderItem[]>([]);

  const fuse = useMemo(
    () =>
      new Fuse(items, {
        keys: ["label", "search"],
        threshold: 0.4,
      }),
    [items]
  );

  const filteredItems = !!searchValue ? searchResults : items;

  const groupedItems: Record<string, RuleExpanderItem[]> = {};
  for (const item of filteredItems) {
    const group = item.group || "";

    if (!groupedItems[group]) {
      groupedItems[group] = [];
    }

    groupedItems[group].push(item);
  }

  const handleClickAny = () => {
    onChange([]);
  };

  const handleClickItem = (id: number) => {
    onChange(
      value.includes(id) ? value.filter((v) => v !== id) : [...value, id]
    );
  };

  const handleSearchChange = (newSearchValue: string) => {
    const result = fuse.search(newSearchValue);

    setSearchValue(newSearchValue);
    setSearchResults(result.map((r) => r.item));
  };

  const handleSelectAll = () => {
    onChange([...new Set([...value, ...filteredItems.map((i) => i.id)])]);
  };

  const handleDeselectAll = () => {
    onChange(value.filter((i) => !filteredItems.map((r) => r.id).includes(i)));
  };

  const orderedGroupedItems = [
    ...Object.entries(groupedItems).filter(([group]) => group === ""),
    ...Object.entries(groupedItems).filter(([group]) => !!group),
  ];

  return (
    <Expander
      title={title}
      defaultExpanded
      expandDisabled
      className={className}
    >
      {search && (
        <>
          <div tw="mt-3">
            <SearchInput
              tw="w-full"
              value={searchValue}
              onChange={(e) => handleSearchChange(e.currentTarget.value)}
              onClear={() => handleSearchChange("")}
            />
          </div>
        </>
      )}
      {(search || selectAll) && (
        <div tw="flex pt-3">
          {search && (
            <div tw="text-gray-600">
              <span tw="font-semibold">{value.length}</span> selected
            </div>
          )}
          {search && selectAll && (
            <div tw="ml-auto flex gap-4">
              <StandardLinkButton
                onClick={handleSelectAll}
                disabled={!filteredItems.find((i) => !value.includes(i.id))}
              >
                Select all
              </StandardLinkButton>
              <StandardLinkButton
                onClick={handleDeselectAll}
                disabled={!filteredItems.find((i) => value.includes(i.id))}
              >
                Deselect all
              </StandardLinkButton>
            </div>
          )}
        </div>
      )}
      <div tw="pt-3">
        {!filteredItems.length ? (
          <span tw="text-type-light">
            {!!searchValue ? "No results." : `No ${itemName}.`}
          </span>
        ) : (
          orderedGroupedItems.map(([group, items]) => (
            <div key={group}>
              {!!group && (
                <div tw="mt-2 text-gray-700 font-semibold uppercase text-sm mb-[0.1rem]">
                  {group}
                </div>
              )}
              <BoxContainer tw="overflow-hidden">
                {includeAny && (
                  <AnyBox isOn={!value.length} onClick={handleClickAny}>
                    Any
                  </AnyBox>
                )}
                {items.map((item) => (
                  <Box
                    key={item.id}
                    isOn={value.includes(item.id)}
                    tw="truncate"
                    css={css`
                      background-color: ${item.color || itemColor};
                    `}
                    onClick={() => handleClickItem(item.id)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </Box>
                ))}
              </BoxContainer>
            </div>
          ))
        )}
      </div>

      {!!createButtons && (
        <div tw="mt-3 flex gap-2">
          {createButtons.filter(isPresent).map((button) => (
            <Tippy
              key={button.label}
              content={button.disabledMessage}
              disabled={!button.disabled || !button.disabledMessage}
            >
              <span>
                <Button
                  type="button"
                  buttonType="alternate-secondary"
                  size="sm"
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  <FontAwesomeIcon icon={faPlus} /> {button.label}
                </Button>
              </span>
            </Tippy>
          ))}
        </div>
      )}
    </Expander>
  );
};

export default RuleExpander;
