import { nanoid } from "nanoid";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import tw, { css, styled } from "twin.macro";

import Spinner from "../Spinner";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";

export type ModalSize = "sm" | "md" | "lg" | "xl" | "2xl";

export type BorderRadiusSize = "auto" | "md" | "lg" | "xl" | "2xl" | "3xl";

interface Props {
  className?: string;
  isOpen: boolean;
  header?: ReactNode;
  isLoading?: boolean;
  size?: ModalSize;
  hidden?: boolean;
  radius?: BorderRadiusSize;
  zIndex?: number;
}

const StyledCSSTranstion = styled(CSSTransition)`
  .overlay {
    opacity: 0.5;
  }

  &.modal-enter {
    .overlay {
      opacity: 0;
    }

    .content {
      opacity: 0;
      margin-top: -2rem;
    }
  }

  &.modal-enter-active {
    .overlay {
      opacity: 0.5;
      transition: opacity 200ms;
    }

    .content {
      opacity: 1;
      margin-top: 0;
      transition: opacity 200ms, margin-top 200ms;
    }
  }

  &.modal-enter-done {
    .overlay {
      opacity: 0.5;
    }

    .content {
      opacity: 1;
      margin-top: 0;
    }
  }

  &.modal-exit {
    .overlay {
      opacity: 0.5;
    }

    .content {
      opacity: 1;
      margin-top: 0;
    }
  }

  &.modal-exit-active {
    .overlay {
      opacity: 0;
      transition: opacity 200ms;
    }

    .content {
      opacity: 0;
      transition: opacity 200ms;
    }
  }
`;
const Container = styled.div<{ zIndex: number }>`
  ${tw`fixed w-full h-full top-0 left-0 flex items-center justify-center`}
  z-index: ${(props) => props.zIndex};
`;
const Overlay = tw.div`absolute w-full h-full bg-gray-300 flex items-center justify-center`;
const Content = styled.div<{
  size: ModalSize;
  radius: BorderRadiusSize;
  zIndex: number;
}>`
  ${tw`bg-white w-11/12 mx-auto shadow-lg overflow-hidden`}
  z-index: ${(props) => props.zIndex};
  ${(props) =>
    props.size === "sm"
      ? tw`md:max-w-sm`
      : props.size === "md"
      ? tw`md:max-w-md`
      : tw`md:max-w-lg`}

  ${(props) => props.size === "xl" && tw`md:max-w-3xl`}
    ${(props) =>
    props.size === "xl" &&
    css`
      min-width: 48rem;
    `}
    ${(props) => props.size === "2xl" && tw`md:max-w-4xl`}

    
    ${(props) =>
    props.radius === "3xl"
      ? tw`rounded-3xl`
      : props.radius === "2xl"
      ? tw`rounded-2xl`
      : props.radius === "xl"
      ? tw`rounded-xl`
      : props.radius === "lg"
      ? tw`rounded-lg`
      : props.radius === "auto"
      ? props.size === "2xl"
        ? tw`rounded-3xl`
        : props.size === "xl"
        ? tw`rounded-2xl`
        : props.size === "lg"
        ? tw`rounded-xl`
        : props.size === "md"
        ? tw`rounded-lg`
        : tw`rounded`
      : tw`rounded`}
`;

const Modal: React.FunctionComponent<Props> = ({
  className,
  isOpen,
  isLoading,
  header,
  children,
  size = "lg",
  hidden,
  radius = "md",
  zIndex = 50,
}) => {
  const [id] = useState(nanoid());
  const nodeRef = useRef(null);

  useLayoutEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  return ReactDOM.createPortal(
    <StyledCSSTranstion
      nodeRef={nodeRef}
      in={isOpen}
      timeout={200}
      classNames="modal"
      unmountOnExit={true}
    >
      <Container
        ref={nodeRef}
        key={id}
        className={className}
        css={hidden ? tw`duration-200 opacity-0` : undefined}
        zIndex={zIndex}
      >
        <Overlay className="overlay" />
        <Content
          className="content"
          size={size}
          radius={radius}
          zIndex={zIndex}
        >
          {!!header && <ModalHeader>{header}</ModalHeader>}
          {isLoading ? (
            <ModalBody>
              <Spinner />
            </ModalBody>
          ) : (
            children
          )}
        </Content>
      </Container>
    </StyledCSSTranstion>,
    document.body
  );
};

export type { Props as ModalProps };

export default Modal;
