import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowOfferChangeFrequencyOptionFragment on offer_change_frequency_option {
    interval
    interval_count
    text_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
