import { isPresent } from "ts-is-present";

import { FlowOfferRulesQuery } from "../../../../__generated__/graphql";
import RuleSegments from "../../../../common/rules/RuleSegments";
import { useFlowVersion } from "../FlowVersionProvider";
import OfferRuleRules from "./OfferRuleRules";

interface ReadOnlyOfferRulesProps {
  offerRuleGroupId: number;
  offerRules: FlowOfferRulesQuery;
}

const ReadOnlyOfferRules: React.FunctionComponent<ReadOnlyOfferRulesProps> = ({
  offerRuleGroupId,
  offerRules,
}) => {
  const { getObjectVersion } = useFlowVersion();

  const offerRuleGroup = offerRules.offer_rule_group.find(
    (group) => group.id === offerRuleGroupId
  );
  if (!offerRuleGroup) {
    throw new Error("Missing offer rule group");
  }

  const groupVersion = getObjectVersion(offerRuleGroup);

  const rules = groupVersion.offer_rule_group_version_offer_rules
    .map((rule) => rule.offer_rule)
    .filter(isPresent);

  return (
    <div tw="flex flex-col w-full mt-4">
      <div tw="flex w-full text-gray-600 font-semibold border-b border-divider pb-1">
        <div tw="w-1/2 px-1">Segment</div>
        <div tw="w-1/2 px-1">Offer</div>
      </div>

      {!rules.length && <div tw="pl-1 py-1 text-type-light">No rules</div>}

      {rules.map((rule) => {
        const ruleVersion = getObjectVersion(rule);
        const ruleRules = ruleVersion.offer_rule_version_offer_rule_rules
          .map((r) => r.offer_rule_rule)
          .filter(isPresent)
          .map((r) => ({
            ...r,
            weight: r.weight,
            offerIds: r.offer_ids,
            offerTestIds: r.offer_test_ids,
            offerGroupIds: r.offer_group_ids,
            offerAutopilotOfferIds: r.offer_autopilot_offer_ids,
            includePresentNoOffer: r.include_present_no_offer,
            deflectionIds: [],
          }));

        return (
          <div key={rule.id} tw="flex w-full border-b py-2">
            <div tw="w-1/2">
              <RuleSegments
                segmentGroups={ruleVersion.offer_rule_version_segment_groups
                  .map((g) => g.segment_group)
                  .filter(isPresent)}
                segments={ruleVersion.offer_rule_version_segments
                  .map((s) =>
                    offerRules.segment.find((test) => test.id === s.segment_id)
                  )
                  .filter(isPresent)}
                inlineSegments={ruleVersion.offer_rule_version_segments
                  .map((s) =>
                    offerRules.inlineSegments.find(
                      (test) => test.id === s.segment_id
                    )
                  )
                  .filter(isPresent)}
                newConditions={[]}
              />
            </div>
            <div tw="w-1/2">
              <OfferRuleRules
                ruleRules={ruleRules}
                offerRules={offerRules}
                isEditing={false}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReadOnlyOfferRules;
