import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  Observable,
  Operation,
  split,
} from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { Subscription } from "zen-observable-ts";

import { UserClaims } from "../@types";
import env from "../common/env";

const clients: Record<string, ReturnType<typeof createApolloClient>> = {};

const defaultMerge = (existing: any, incoming: any) => incoming;

export const createApolloClient = (
  userClaims: UserClaims | undefined,
  extraHeaders: Record<string, string> = {},
  cacheKey?: string
): ApolloClient<NormalizedCacheObject> => {
  if (cacheKey && clients[cacheKey]) {
    return clients[cacheKey];
  }

  const resolveHeaders = () => {
    const headers = { ...extraHeaders };

    if (userClaims) {
      headers.authorization = `Bearer ${userClaims.token.raw}`;

      let defaultRole = userClaims.defaultRole;
      if (
        window.location.pathname.match(/^\/a\/.*/) &&
        userClaims.allowedRoles.includes("admin")
      ) {
        defaultRole = "admin";
      }

      headers["x-hasura-role"] = defaultRole;
    }

    return headers;
  };

  const request = async (operation: Operation) => {
    operation.setContext({
      headers: resolveHeaders(),
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: Subscription;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      })
  );

  const httpLink = from([
    new RetryLink(),
    new HttpLink({
      uri: env("REACT_APP_GRAPHQL_URI", "http://localhost:8824/v1/graphql"),
      credentials: "same-origin",
    }),
  ]);

  const wsLink = new WebSocketLink({
    uri: env("REACT_APP_GRAPHQL_WS_URI", "ws://localhost:8824/v1/graphql"),
    options: {
      reconnect: true,
      connectionParams: () => ({
        headers: resolveHeaders(),
      }),
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    requestLink,
    splitLink,
  ]);

  const cache = new InMemoryCache({
    typePolicies: {
      flow: {
        fields: {
          flow_on_cancel_change_plans: {
            merge: defaultMerge,
          },
        },
      },
      flow_text: {
        keyFields: ["flow_id", "key"],
      },
      translation_value: {
        keyFields: ["translation_id", "language"],
      },
      flow_acknowledgement: {
        keyFields: ["flow_id", "acknowledgement_id"],
      },
      flow_language: {
        keyFields: ["flow_id", "language"],
      },
      flow_step_deflection_rule_group: {
        keyFields: ["flow_step_id", "offer_rule_group_id"],
      },
      flow_step_question: {
        keyFields: ["flow_step_id"],
      },
      deflection: {
        keyFields: ["id"],
        fields: {
          deflection_flow_actions: {
            merge: defaultMerge,
          },
        },
      },
      deflection_action_button: {
        keyFields: ["deflection_action_id"],
      },
      email: {
        keyFields: ["id"],
        fields: {
          data: {
            merge: defaultMerge,
          },
        },
      },
      flow_version: {
        fields: {
          flow_version_flow_steps: {
            merge: defaultMerge,
          },
        },
      },
      flow_step_version: {
        fields: {
          condition_segment_ids: {
            merge: defaultMerge,
          },
          condition_question_option_ids: {
            merge: defaultMerge,
          },
          flow_step_version_condition_segments: {
            merge: defaultMerge,
          },
          flow_step_version_condition_question_options: {
            merge: defaultMerge,
          },
        },
      },
      flow_test_flow: {
        keyFields: ["flow_test_id", "flow_id"],
      },
      flow_step_offer_rule_group: {
        keyFields: ["flow_step_id", "offer_rule_group_id"],
      },
      offer_test_offer: {
        keyFields: ["offer_test_id", "offer_id"],
      },
      subscriber_property: {
        keyFields: ["subscriber_id", "property_id"],
      },
      account_feature: {
        keyFields: ["key"],
      },
      account_setting: {
        keyFields: ["account_id", "key"],
      },
      account_user_setting: {
        keyFields: ["account_id", "user_id", "key"],
      },
    },
  });

  const client = new ApolloClient({
    connectToDevTools: env("NODE_ENV") === "development",
    link,
    cache,
  });

  if (cacheKey) {
    clients[cacheKey] = client;
  }

  return client;
};
