import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { styled, theme } from "twin.macro";

import { OfferPlatformEcommerceProductsQuery } from "../../../../__generated__/graphql";
import FieldRadioInput from "../../../../common/form/FieldRadioInput";
import FormMode from "../../../../common/form/FormMode";
import { FreeGiftOfferFormValues } from "../types";

interface FreeGiftVariantsFormPartialProps {
  mode: FormMode;
  product:
    | OfferPlatformEcommerceProductsQuery["platform_ecommerce_product"][number]
    | null;
  initialValue: string | null;
  register: UseFormRegister<FreeGiftOfferFormValues>;
  setValue: UseFormSetValue<FreeGiftOfferFormValues>;
}

const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  flex: 1 0 0;
  width: 100%;
`;

const VariantsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  align-self: stretch;
`;

const VariantsTitle = styled.div`
  flex: 1 0 0;
  color: var(--Gray-2, ${theme`colors.gray.800`});
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const VariantRow = styled.div<{
  hasBorder: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.325rem;
  align-self: stretch;
  padding-bottom: ${(props) => (props.hasBorder ? "0.325rem" : "0")};
  border-bottom: ${(props) =>
    props.hasBorder ? `0.0625rem solid ${theme`colors.divider`}` : "0"};
  width: 100%;
`;

const VariantLabel = styled.label`
  color: var(--Gray-1, #333);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const FreeGiftVariantsFormPartial: React.FunctionComponent<
  FreeGiftVariantsFormPartialProps
> = ({ mode, product, initialValue, register, setValue }) => (
  <VariantsWrapper>
    <VariantsHeader>
      <VariantsTitle>Variants</VariantsTitle>
    </VariantsHeader>
    {product?.platform_variants.map((item, index) => (
      <FieldRadioInput key={item.platform_id} tw="w-full">
        <VariantRow hasBorder={index !== product.platform_variants.length - 1}>
          <input
            {...register("variantId")}
            id={item.name}
            type="radio"
            value={item.platform_id}
            onChange={(v) => {
              setValue("variantId", v.target.value);
              setValue("variantName", item.name);
            }}
          />
          <VariantLabel htmlFor={item.name}>{item.name}</VariantLabel>
        </VariantRow>
      </FieldRadioInput>
    ))}
  </VariantsWrapper>
);

export default FreeGiftVariantsFormPartial;
