import { createContext, useContext } from "react";

import {
  offer_goal_enum,
  offer_pause_subscription_option_type_enum,
} from "../../../__generated__/graphql";

export interface CouponOfferDetails {
  type: "coupon";
  goal: offer_goal_enum;
  couponType: string | null | undefined;
  amountOff: number | null | undefined;
  duration: string | null | undefined;
  durationInterval: string | null | undefined;
  durationCount: number | null | undefined;
  months: number | null | undefined;
}

export interface ChangeFrequencyOfferDetails {
  type: "change_frequency";
  goal: offer_goal_enum;
  options: Array<{
    interval: string | null | undefined;
    intervalCount: number | null | undefined;
  }>;
}

export interface ChangePlanOfferDetails {
  type: "change_plan";
  goal: offer_goal_enum;
  planId: string | null | undefined;
  planName: string | null | undefined;
}

export interface FreeGiftOfferDetails {
  type: "free_gift";
  goal: offer_goal_enum;
  productId: string | null | undefined;
  productName: string | null | undefined;
  productImageUrl: string | null | undefined;
  variantId: string | null | undefined;
  variantName: string | null | undefined;
}

export interface ModifySubscriptionOfferDetails {
  type: "modify_subscription";
  goal: offer_goal_enum;
}

export interface TrialExtensionOfferDetails {
  type: "trial_extension";
  goal: offer_goal_enum;
  days: number | null | undefined;
}

export interface PauseSubscriptionOfferDetails {
  type: "pause_subscription";
  goal: offer_goal_enum;
  options: Array<{
    type: offer_pause_subscription_option_type_enum;
    interval: string | null | undefined;
    intervalCount: number | null | undefined;
  }>;
}

export interface UpgradeOfferDetails {
  type: "upgrade";
  goal: offer_goal_enum;
  planId: string | null | undefined;
  planName: string | null | undefined;
}

export interface DowngradeOfferDetails {
  type: "downgrade";
  goal: offer_goal_enum;
  planId: string | null | undefined;
  planName: string | null | undefined;
}

export interface ProductSwapOfferDetails {
  type: "product_swap";
  goal: offer_goal_enum;
  specificProducts: boolean;
  products: any[]; // @TODO
}

export interface ReactivateOfferDetails {
  type: "reactivate";
  goal: offer_goal_enum;
}

export interface RescheduleOrderOfferDetails {
  type: "reschedule_order";
  goal: offer_goal_enum;
  skipEnabled: boolean;
  rescheduleEnabled: boolean;
}

export interface CustomOfferDetails {
  type: "custom";
  goal: offer_goal_enum;
}

export type OfferDetails =
  | CouponOfferDetails
  | ChangeFrequencyOfferDetails
  | ChangePlanOfferDetails
  | FreeGiftOfferDetails
  | ModifySubscriptionOfferDetails
  | TrialExtensionOfferDetails
  | PauseSubscriptionOfferDetails
  | UpgradeOfferDetails
  | DowngradeOfferDetails
  | ProductSwapOfferDetails
  | ReactivateOfferDetails
  | RescheduleOrderOfferDetails
  | CustomOfferDetails;

interface OfferDetailsContext {
  offerDetails: OfferDetails;
  showPlaceholders?: boolean;
}

const Context = createContext<OfferDetailsContext | undefined>(undefined);

export const useOfferDetails = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      "useOfferDetails() not called within OfferDetailsProvider context"
    );
  }

  return context;
};

interface OfferDetailsProviderProps {
  offerDetails: OfferDetails;
  showPlaceholders?: boolean;
}

export const OfferDetailsProvider: React.FunctionComponent<
  OfferDetailsProviderProps
> = ({ offerDetails, showPlaceholders, children }) => (
  <Context.Provider value={{ offerDetails, showPlaceholders }}>
    {children}
  </Context.Provider>
);
