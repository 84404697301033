import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";

import StripePaymentForm from "./StripePaymentForm";
import { PaymentFormProps } from "./types";

interface StripePaymentProps extends PaymentFormProps {
  publishableKey: string;
  returnUrl: string;
  createSetupIntent: () => Promise<string>;
  fullWidth?: boolean;
}

const StripePayment: React.FunctionComponent<StripePaymentProps> = ({
  publishableKey,
  ...props
}) => {
  const stripePromise = useMemo(
    () => loadStripe(publishableKey),
    [publishableKey]
  );

  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: "setup", currency: "usd" }}
    >
      <StripePaymentForm {...props} />
    </Elements>
  );
};

export default StripePayment;
