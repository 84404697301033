import { gql, useMutation, useQuery } from "@apollo/client";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import tw, { styled, theme } from "twin.macro";

import {
  AppSegmentGroupFragment,
  SegmentGroupPanelCreateSegmentGroupMutation,
  SegmentGroupPanelCreateSegmentGroupMutationVariables,
  SegmentGroupPanelExistingSegmentGroupsQuery,
  SegmentGroupPanelExistingSegmentGroupsQueryVariables,
  SegmentGroupPanelUpdateSegmentGroupMutation,
  SegmentGroupPanelUpdateSegmentGroupMutationVariables,
} from "../../__generated__/graphql";
import BooleanDivider from "../../common/BooleanDivider";
import Callout from "../../common/Callout";
import Button from "../../common/form/Button";
import FieldError from "../../common/form/FieldError";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FormMode from "../../common/form/FormMode";
import TextInput from "../../common/form/input/TextInput";
import AppSegmentFragment from "../../common/fragments/AppSegmentFragment";
import TheAppSegmentGroupFragment from "../../common/fragments/AppSegmentGroupFragment";
import HelpBlock from "../../common/HelpBlock";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelFormBody from "../../common/panel/PanelFormBody";
import PanelTitle from "../../common/panel/PanelTitle";
import RuleExpander from "../../common/rules/RuleExpander";
import StandardExternalLink from "../../common/StandardExternalLink";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import usePrevious from "../../common/usePrevious";
import SegmentIntegrationIcon from "./SegmentIntegrationIcon";

const StyledFieldRowBlock = styled(FieldRowBlock)`
  ${tw`max-w-[450px] overflow-hidden`}
`;

interface FormValues {
  name: string;
  orSegmentIds: number[];
  andSegmentIds: number[];
  notSegmentIds: number[];
}

interface SegmentGroupPanelProps extends PanelProps {
  mode: FormMode;
  segmentGroup?: AppSegmentGroupFragment;
  inUseByFlow?: boolean;
  hasHistoricalData?: boolean;
  onClose: (segmentGroup?: AppSegmentGroupFragment) => void;
}

const validate = (value: number[], formValues: FormValues) =>
  [
    ...formValues.orSegmentIds,
    ...formValues.andSegmentIds,
    ...formValues.notSegmentIds,
  ].length > 0;

const SegmentGroupPanel: React.FunctionComponent<SegmentGroupPanelProps> = ({
  mode,
  segmentGroup,
  inUseByFlow,
  hasHistoricalData,
  onClose,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  const { addToast } = useToasts();
  useFocusFirstEmptyInput(formRef);

  const { data, refetch } = useQuery<
    SegmentGroupPanelExistingSegmentGroupsQuery,
    SegmentGroupPanelExistingSegmentGroupsQueryVariables
  >(
    gql`
      query SegmentGroupPanelExistingSegmentGroupsQuery {
        segment(where: { inline: { _eq: false } }, order_by: [{ name: asc }]) {
          ...AppSegmentFragment
        }

        segment_group {
          id
          name
        }
      }
      ${AppSegmentFragment}
    `,
    {
      fetchPolicy: "cache-and-network",
      skip: !props.isOpen,
    }
  );

  const isOpen = props.isOpen;
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      refetch();
    }
  }, [isOpen, prevIsOpen, refetch]);

  const [createSegmentGroup] = useMutation<
    SegmentGroupPanelCreateSegmentGroupMutation,
    SegmentGroupPanelCreateSegmentGroupMutationVariables
  >(gql`
    mutation SegmentGroupPanelCreateSegmentGroupMutation(
      $input: CreateSegmentGroupInput!
    ) {
      createSegmentGroup(input: $input) {
        segmentGroup {
          ...AppSegmentGroupFragment
        }
      }
    }
    ${TheAppSegmentGroupFragment}
  `);

  const [updateSegmentGroup] = useMutation<
    SegmentGroupPanelUpdateSegmentGroupMutation,
    SegmentGroupPanelUpdateSegmentGroupMutationVariables
  >(gql`
    mutation SegmentGroupPanelUpdateSegmentGroupMutation(
      $input: UpdateSegmentGroupInput!
    ) {
      updateSegmentGroup(input: $input) {
        segmentGroup {
          ...AppSegmentGroupFragment
        }
      }
    }
    ${TheAppSegmentGroupFragment}
  `);

  const { register, handleSubmit, watch, formState, control } =
    useForm<FormValues>({
      defaultValues: {
        name: segmentGroup?.name || "",
        orSegmentIds:
          segmentGroup?.segment_group_segments
            .filter((s) => s.operator === "or")
            .map((s) => s.segment_id) || [],
        andSegmentIds:
          segmentGroup?.segment_group_segments
            .filter((s) => s.operator === "and")
            .map((s) => s.segment_id) || [],
        notSegmentIds:
          segmentGroup?.segment_group_segments
            .filter((s) => s.operator === "not")
            .map((s) => s.segment_id) || [],
      },
    });

  const orSegmentIds = watch("orSegmentIds");
  const andSegmentIds = watch("andSegmentIds");
  const notSegmentIds = watch("notSegmentIds");

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true);

    if (mode === "create") {
      const result = await createSegmentGroup({
        variables: {
          input: {
            name: values.name,
            orSegmentIds: values.orSegmentIds,
            andSegmentIds: values.andSegmentIds,
            notSegmentIds: values.notSegmentIds,
          },
        },
      });

      if (result.data?.createSegmentGroup.segmentGroup) {
        onClose(result.data.createSegmentGroup.segmentGroup);
      } else {
        addToast(<div>Oops! The segment group couldn't be created.</div>, {
          appearance: "error",
        });
        setIsSubmitting(false);
      }
    } else {
      if (!segmentGroup) {
        throw new Error();
      }

      const result = await updateSegmentGroup({
        variables: {
          input: {
            segmentGroupId: segmentGroup.id,
            name: values.name,
            orSegmentIds: values.orSegmentIds,
            andSegmentIds: values.andSegmentIds,
            notSegmentIds: values.notSegmentIds,
          },
        },
      });

      if (result.data?.updateSegmentGroup.segmentGroup) {
        onClose(result.data.updateSegmentGroup.segmentGroup);
      } else {
        addToast(<div>Oops! The segment group couldn't be created.</div>, {
          appearance: "error",
        });
        setIsSubmitting(false);
      }
    }
  });

  const segmentGroups = data?.segment_group || [];
  const segments = data?.segment || [];

  return (
    <Panel
      {...props}
      header={
        <>
          <PanelTitle>
            {mode === "create" ? (
              <>Create segment group</>
            ) : (
              <>Edit segment group</>
            )}
          </PanelTitle>
          <PanelButtons>
            <Button
              buttonType="primary"
              form="create-segment"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              type="button"
              buttonType="default"
              onClick={() => onClose()}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </PanelButtons>
        </>
      }
    >
      <PanelFormBody>
        {mode === "create" && (
          <HelpBlock
            tw="mt-4 mb-4"
            size="sm"
            color="gray"
            icon={
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={theme`colors.gray.400`}
              />
            }
            content={
              <>
                Group segments for advanced targeting in offer and flow rules.{" "}
                <StandardExternalLink
                  href="https://docs.prosperstack.com/customer-segmentation/creating-a-segment-group"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more.
                </StandardExternalLink>
              </>
            }
          />
        )}

        {mode === "edit" && inUseByFlow && (
          <div tw="mt-3 mb-4">
            <Callout
              type="warning"
              heading="Segment group is in use by a cancellation flow"
            >
              Changing the group's segments may affect your live cancellation
              flow.
            </Callout>
          </div>
        )}
        {mode === "edit" && hasHistoricalData && (
          <div css={inUseByFlow ? tw`mb-4` : tw`mt-3 mb-4`}>
            <Callout type="neutral" heading="Segment group has historical data">
              Changing the group's segments may affect your historical data and
              reporting.
            </Callout>
          </div>
        )}
        <form
          id="create-segment"
          onSubmit={onSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <fieldset disabled={isSubmitting}>
            <FieldRow>
              <FieldLabel>
                <label htmlFor="name">Name</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("name", {
                    required: true,
                    validate: (value) => {
                      if (
                        value.trim() !== "" &&
                        segmentGroups.find(
                          (a) => a.id !== segmentGroup?.id && a.name === value
                        )
                      ) {
                        return "A segment group with that name already exists.";
                      }
                    },
                  })}
                  id="name"
                  width="full"
                  fieldError={formState.errors.name}
                />
                <FieldError error={formState.errors.name} />
              </FieldInput>
            </FieldRow>
            <div tw="font-semibold mt-4">
              Subscriber will belong to this segment group if…
            </div>
            <StyledFieldRowBlock tw="border-b-0 mb-3">
              <FieldLabel tw="w-full pb-3 font-normal text-type-light">
                <label>
                  the subscriber belongs to <strong>any</strong> of these
                  segments…
                </label>
              </FieldLabel>
              <Controller
                control={control}
                name="orSegmentIds"
                rules={{ validate }}
                render={({ field }) => (
                  <RuleExpander
                    items={segments.map((s) => ({
                      id: s.id,
                      label: (
                        <span tw="truncate">
                          {s.name}
                          {!!s.integration && (
                            <SegmentIntegrationIcon
                              integrationType={s.integration.type}
                              color="light"
                            />
                          )}
                        </span>
                      ),
                      search: s.name,
                      group:
                        s.integration?.type === "klaviyo"
                          ? "Klaviyo"
                          : undefined,
                      disabled:
                        andSegmentIds.includes(s.id) ||
                        notSegmentIds.includes(s.id),
                    }))}
                    itemColor={theme`colors.purple.600`}
                    search={segments.length >= 20}
                    selectAll
                    itemName="segments"
                    value={field.value}
                    onChange={field.onChange}
                    tw="-mt-4"
                  />
                )}
              />
            </StyledFieldRowBlock>
            <BooleanDivider operator="and" />
            <StyledFieldRowBlock tw="border-b-0 mb-3">
              <FieldLabel tw="w-full pb-3 font-normal text-type-light">
                <label>
                  the subscriber belongs to <strong>all</strong> of these
                  segments…
                </label>
              </FieldLabel>
              <Controller
                control={control}
                name="andSegmentIds"
                rules={{ validate }}
                render={({ field }) => (
                  <RuleExpander
                    items={segments.map((s) => ({
                      id: s.id,
                      label: (
                        <span tw="truncate">
                          {s.name}
                          {!!s.integration && (
                            <SegmentIntegrationIcon
                              integrationType={s.integration.type}
                              color="light"
                            />
                          )}
                        </span>
                      ),
                      search: s.name,
                      group:
                        s.integration?.type === "klaviyo"
                          ? "Klaviyo"
                          : undefined,
                      disabled:
                        orSegmentIds.includes(s.id) ||
                        notSegmentIds.includes(s.id),
                    }))}
                    itemColor={theme`colors.purple.600`}
                    search={segments.length >= 20}
                    selectAll
                    itemName="segments"
                    value={field.value}
                    onChange={field.onChange}
                    tw="-mt-4"
                  />
                )}
              />
            </StyledFieldRowBlock>
            <BooleanDivider operator="and" />
            <StyledFieldRowBlock tw="border-b-0 mb-3">
              <FieldLabel tw="w-full pb-3 font-normal text-type-light">
                <label>
                  the subscriber belongs to <strong>none</strong> of these
                  segments.
                </label>
              </FieldLabel>
              <Controller
                control={control}
                name="notSegmentIds"
                rules={{ validate }}
                render={({ field }) => (
                  <RuleExpander
                    items={segments.map((s) => ({
                      id: s.id,
                      label: (
                        <span tw="truncate">
                          {s.name}
                          {!!s.integration && (
                            <SegmentIntegrationIcon
                              integrationType={s.integration.type}
                              color="light"
                            />
                          )}
                        </span>
                      ),
                      search: s.name,
                      group:
                        s.integration?.type === "klaviyo"
                          ? "Klaviyo"
                          : undefined,
                      disabled:
                        andSegmentIds.includes(s.id) ||
                        orSegmentIds.includes(s.id),
                    }))}
                    itemColor={theme`colors.purple.600`}
                    search={segments.length >= 20}
                    selectAll
                    itemName="segments"
                    value={field.value}
                    onChange={field.onChange}
                    tw="-mt-4"
                  />
                )}
              />
              {(formState.errors.orSegmentIds ||
                formState.errors.andSegmentIds ||
                formState.errors.notSegmentIds) && (
                <div tw="mt-5 text-red-500 leading-normal">
                  At least two segments must be selected.
                </div>
              )}
            </StyledFieldRowBlock>
          </fieldset>
        </form>
      </PanelFormBody>
    </Panel>
  );
};

export default SegmentGroupPanel;
