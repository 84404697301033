import { Helmet } from "react-helmet-async";
import { ToastProvider } from "react-toast-notifications";

import DefaultToast from "../../../common/DefaultToast";
import GlobalStyles from "./GlobalStyles";

const Layout: React.FunctionComponent<{ mode?: string }> = ({
  mode = "portal",
  children,
}) => (
  <ToastProvider
    autoDismiss={true}
    transitionDuration={200}
    components={{ Toast: DefaultToast }}
    placement="top-center"
  >
    <Helmet>
      <title></title>
    </Helmet>
    <GlobalStyles />
    {mode === "reactivate" ? (
      <div tw="w-full min-h-screen flex justify-center">{children}</div>
    ) : (
      <div tw="mx-8 md:min-w-[660px] pt-8">
        <div tw="w-full flex justify-center py-12">
          <div tw="w-full md:w-auto">{children}</div>
        </div>
      </div>
    )}
  </ToastProvider>
);

export default Layout;
