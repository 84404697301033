import { gql, useMutation, useQuery } from "@apollo/client";

import {
  CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutation,
  CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutationVariables,
  CustomerPortalPaymentMethodModalQuery,
  CustomerPortalPaymentMethodModalQueryVariables,
  CustomerPortalPaymentMethodModalSetPaymentMethodMutation,
  CustomerPortalPaymentMethodModalSetPaymentMethodMutationVariables,
} from "../../../__generated__/graphql";
import Modal, { ModalProps } from "../../../common/modal/Modal";
import ModalHeader from "../../../common/modal/ModalHeader";
import Spinner from "../../../common/Spinner";
import ChargebeePayment from "../payment/ChargebeePayment";
import RecurlyPayment from "../payment/RecurlyPayment";
import StripePayment from "../payment/StripePayment";
import { PaymentFormOnCompleteResult } from "../payment/types";

interface PaymentMethodModalProps extends ModalProps {
  sessionToken: string;
  onClose: (updated: boolean) => void;
}

const PaymentMethodModal: React.FunctionComponent<PaymentMethodModalProps> = ({
  sessionToken,
  onClose,
  ...props
}) => {
  const { data } = useQuery<
    CustomerPortalPaymentMethodModalQuery,
    CustomerPortalPaymentMethodModalQueryVariables
  >(
    gql`
      query CustomerPortalPaymentMethodModalQuery(
        $input: PortalSessionPaymentInfoInput!
      ) {
        portalSessionPaymentInfo(input: $input) {
          platform
          platformId
          publishableKey
          addressRequirements
        }
      }
    `,
    {
      skip: !props.isOpen,
      variables: {
        input: {
          portalSessionToken: sessionToken,
        },
      },
    }
  );

  const [createSetupIntent] = useMutation<
    CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutation,
    CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutationVariables
  >(gql`
    mutation CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutation(
      $input: CreatePortalSessionStripeSetupIntentInput!
    ) {
      createPortalSessionStripeSetupIntent(input: $input) {
        clientSecret
      }
    }
  `);

  const paymentInfo = data?.portalSessionPaymentInfo;

  const handleCreateSetupIntent = async () => {
    const result = await createSetupIntent({
      variables: {
        input: {
          portalSessionToken: sessionToken,
        },
      },
    });

    if (!result.data?.createPortalSessionStripeSetupIntent.clientSecret) {
      throw new Error();
    }

    return result.data.createPortalSessionStripeSetupIntent.clientSecret;
  };

  const [setPaymentMethod] = useMutation<
    CustomerPortalPaymentMethodModalSetPaymentMethodMutation,
    CustomerPortalPaymentMethodModalSetPaymentMethodMutationVariables
  >(gql`
    mutation CustomerPortalPaymentMethodModalSetPaymentMethodMutation(
      $input: SetPortalSessionPaymentMethodInput!
    ) {
      setPortalSessionPaymentMethod(input: $input) {
        success
        errorMessage
      }
    }
  `);

  const handleComplete = async (
    paymentMethodId: string
  ): Promise<PaymentFormOnCompleteResult> => {
    const result = await setPaymentMethod({
      variables: {
        input: {
          portalSessionToken: sessionToken,
          paymentMethodId,
        },
      },
    });

    const mutationResult = result.data?.setPortalSessionPaymentMethod;

    let errorMessage;

    if (!mutationResult?.success) {
      errorMessage =
        mutationResult?.errorMessage ??
        "Something went wrong with your request.";
    } else {
      onClose(true);
    }

    return {
      success: !!mutationResult?.success,
      errorMessage,
    };
  };

  return (
    <Modal {...props}>
      <ModalHeader>Update payment method</ModalHeader>
      <div tw="p-4">
        {!paymentInfo ? (
          <Spinner />
        ) : (
          <>
            {paymentInfo.platform === "stripe" && (
              <StripePayment
                publishableKey={paymentInfo.publishableKey}
                returnUrl={window.location.href}
                createSetupIntent={handleCreateSetupIntent}
                onComplete={handleComplete}
                onCancel={() => onClose(false)}
              />
            )}
            {paymentInfo.platform === "chargebee" && (
              <ChargebeePayment
                publishableKey={paymentInfo.publishableKey}
                site={paymentInfo.platformId}
                onComplete={handleComplete}
                onCancel={() => onClose(false)}
              />
            )}
            {paymentInfo.platform === "recurly" && (
              <RecurlyPayment
                publicKey={paymentInfo.publishableKey}
                addressRequirements={paymentInfo.addressRequirements || ""}
                onComplete={handleComplete}
                onCancel={() => onClose(false)}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default PaymentMethodModal;
