import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React from "react";
import tw, { css, styled } from "twin.macro";

import {
  ChildSegmentConditionGroupFragment,
  SegmentConditionFragment,
  SegmentConditionGroupEntryFragment,
} from "../../__generated__/graphql";
import {
  getFriendlyValue,
  getOperatorDisplay,
  propertyConfigs,
} from "../../common/segments/lib";

interface SegmentConditionDescriptionProps {
  condition: SegmentConditionFragment;
  active?: boolean;
  className?: string;
  onRemove?: () => void;
  highlight?: boolean;
}

const BooleanOperator = tw.div`uppercase font-bold last:hidden my-2`;

const ChildGroup: React.FunctionComponent<{
  group: ChildSegmentConditionGroupFragment;
  className?: string;
  active?: boolean;
  highlight?: boolean;
}> = ({ group, className, active, highlight = true }) => (
  <div tw="flex">
    <div tw="w-3 border-l border-t border-b border-divider"></div>
    <div tw="py-4 ml-1">
      {group.segment_condition_group_entries.map((e) => (
        <React.Fragment key={e.entry_segment_condition?.id}>
          <Entry
            entry={e}
            className={className}
            highlight={highlight}
            active={active}
          />
          <BooleanOperator>{group.boolean_operator}</BooleanOperator>
        </React.Fragment>
      ))}
    </div>
  </div>
);

const LightText = styled.span<{ active: boolean; highlighted: boolean }>`
  ${tw`italic`}
  ${(props) =>
    !props.highlighted
      ? tw`text-type-light`
      : !!props.active
      ? tw`text-white`
      : tw`text-type-light`}
`;
const Value = tw.span`ml-[2px]`;

const Condition: React.FunctionComponent<{
  condition: SegmentConditionFragment;
  active: boolean;
  className?: string;
  onRemove?: () => void;
  highlight?: boolean;
}> = ({ condition, active, className, onRemove, highlight = true }) => {
  const property =
    condition.property !== "custom_property"
      ? propertyConfigs[condition.property]
      : undefined;

  const group =
    property?.entity === "subscriber" || condition.custom_property?.entity
      ? "Subscriber"
      : property?.entity === "subscription" || condition.custom_property?.entity
      ? "Subscription"
      : null;

  const name = property?.name ? property.name : condition.custom_property?.name;

  const description = (() => {
    if (condition.operator === "within_last_days") {
      return (
        <>
          <LightText active highlighted={highlight}>
            is within the last
          </LightText>{" "}
          <Value>{condition.value}</Value>{" "}
          <LightText active highlighted={highlight}>
            days
          </LightText>
        </>
      );
    }

    if (condition.operator === "more_than_days_ago") {
      return (
        <>
          <LightText active highlighted={highlight}>
            is more than
          </LightText>{" "}
          <Value>{condition.value}</Value>{" "}
          <LightText active highlighted={highlight}>
            days ago
          </LightText>
        </>
      );
    }

    if (condition.operator === "within_next_days") {
      return (
        <>
          <LightText active highlighted={highlight}>
            is within the next
          </LightText>{" "}
          <Value>{condition.value}</Value>{" "}
          <LightText active highlighted={highlight}>
            days
          </LightText>
        </>
      );
    }

    if (condition.operator === "more_than_days_from_now") {
      return (
        <>
          <LightText active highlighted={highlight}>
            is more than
          </LightText>{" "}
          <Value>{condition.value}</Value>{" "}
          <LightText active highlighted={highlight}>
            days from now
          </LightText>
        </>
      );
    }

    return (
      <>
        <span css={active ? tw`italic text-white` : tw`italic text-type-light`}>
          {getOperatorDisplay(condition.property, condition.operator)}
        </span>{" "}
        <Value>
          {getFriendlyValue(
            condition.property,
            condition.value,
            condition.operator === "in" || condition.operator === "nin",
            condition.custom_property
          )}
        </Value>
      </>
    );
  })();

  return (
    <div
      tw="flex items-center overflow-hidden px-2"
      css={[
        !!highlight
          ? active
            ? tw`bg-purple-600 text-white px-2 rounded`
            : tw`bg-gray-200 px-2 rounded`
          : undefined,
      ]}
      className={className}
    >
      <Tippy
        content={
          <span
            css={css`
              * {
                ${tw`text-white break-words`}
              }
            `}
          >
            <strong>{group}</strong> - {name || ""} {description}
          </span>
        }
        maxWidth={600}
      >
        <div tw="leading-[1.2rem] py-1 truncate">
          <span>
            <strong>{group}</strong> - {name || ""} {description}{" "}
          </span>
        </div>
      </Tippy>
      {onRemove && (
        <div tw="ml-auto">
          <button
            css={[
              !highlight && tw`ml-2 text-gray-500 hover:text-gray-800`,
              active && tw`text-white hover:text-white`,
            ]}
            type="button"
            onClick={onRemove}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );
};

const Entry: React.FunctionComponent<{
  entry: SegmentConditionGroupEntryFragment;
  active?: boolean;
  className?: string;
  highlight?: boolean;
}> = ({ entry, active = false, className, highlight = true }) => {
  if (entry.entry_segment_condition_group) {
    return (
      <ChildGroup
        group={entry.entry_segment_condition_group}
        active={active}
        highlight={highlight}
      />
    );
  }

  if (entry.entry_segment_condition) {
    return (
      <Condition
        condition={entry.entry_segment_condition}
        active={active}
        className={className}
        highlight={highlight}
      />
    );
  }

  return null;
};

const SegmentConditionDescription: React.FunctionComponent<
  SegmentConditionDescriptionProps
> = ({ condition, active = false, className, onRemove, highlight }) => (
  <Condition
    condition={condition}
    active={active}
    className={className}
    onRemove={onRemove}
    highlight={highlight}
  />
);

export default SegmentConditionDescription;
