import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

import {
  FlowSubscriptionFragment,
  OfferPlatformEcommerceProductsQuery,
  platform_currency_enum,
} from "../../../__generated__/graphql";
import TheFlowPlatformEcommerceProductFragment from "../../../common/fragments/FlowPlatformEcommerceProductFragment";

export default function useSwappableProducts(skip: boolean): {
  data: OfferPlatformEcommerceProductsQuery | undefined;
  swappableProducts: FlowSubscriptionFragment;
} {
  const { data } = useQuery<OfferPlatformEcommerceProductsQuery>(
    gql`
      query OfferPlatformEcommerceProductsQuery {
        platform_ecommerce_product(limit: 150) {
          ...FlowPlatformEcommerceProductFragment
        }
      }
      ${TheFlowPlatformEcommerceProductFragment}
    `,
    { skip }
  );

  const swappableProducts: FlowSubscriptionFragment = useMemo(
    () => ({
      __typename: "subscription",
      id: 1,
      platform_subscription: {
        __typename: "platform_subscription",
        platform_id: "1",
        swappable_ecommerce_products:
          data?.platform_ecommerce_product.map((p) => ({
            __typename:
              "platform_subscription_plan_swappable_ecommerce_product",
            platform_ecommerce_product: p,
          })) || [],
        platform_subscription_plans: [
          {
            __typename: "platform_subscription_plan",
            platform_variant: {
              __typename: "platform_variant",
              platform_id: "preview",
              platform_variant_options: [],
              price: "9.99",
              currency: platform_currency_enum.usd,
              available: true,
              platform_ecommerce_product: {
                __typename: "platform_ecommerce_product",
                name: "Example Product",
                platform_id: "preview",
              },
            },
          },
        ],
      },
    }),
    [data]
  );

  return { data, swappableProducts };
}
