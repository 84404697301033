import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useEffect, useState } from "react";
import React from "react";
import tw, { styled, theme } from "twin.macro";

import {
  AppPropertyFragment,
  property_entity_enum,
  segment_condition_property_enum,
} from "../../__generated__/graphql";
import Button from "../../common/form/Button";
import sharedInputStyles from "../../common/form/input/sharedInputStyles";
import SearchInput from "../../common/search/SearchInput";
import {
  ALL_PLATFORMS,
  SegmentConditionPropertyConfig,
} from "../../common/segments/lib";
import useAccountFeatures from "../../common/useAccountFeatures";

interface SegmentPropertyDropdownProps {
  groups: Record<string, SegmentConditionPropertyConfig[]>;
  properties: AppPropertyFragment[];
  value: segment_condition_property_enum | number;
  onChange: (value: segment_condition_property_enum | number) => void;
  onClickCreateProperty?: (entity: property_entity_enum) => void;
}

const Menu = styled.div`
  width: 38.5rem;
`;
const MenuBody = styled.div`
  ${tw`text-base px-2 py-1 rounded-b-md`}
  overflow-y: auto;
  max-height: 29.5rem;
`;
const PropertyGroup = tw.div`mb-2 pt-2 last:mb-0`;
const Label = tw.label`block font-semibold mb-2.5 pb-2.5 border-b border-neutral-200`;

const PropertySubGroup = styled.div`
  ${tw`-mt-1 pb-1 last:pb-0`}
  label {
    ${tw`text-xs font-semibold uppercase border-b-0 pb-0 mb-1`}
    color: var(--Gray-3, #828282);
  }
`;

const PropertyButton = styled.button`
  ${tw`text-type rounded-md bg-gray-100 hover:bg-gray-200 mr-2 mb-2 active:bg-blue active:border-blue active:text-white outline-none focus:outline-none truncate`}

  max-width: 12rem;
  line-height: 1.5rem;
  font-size: 1rem;
  padding: 0rem 0.375rem;

  &.active {
    ${tw`bg-blue border-blue text-white`}
  }
`;

const DropdownButton = styled.button`
  ${sharedInputStyles}
  ${tw`flex whitespace-nowrap flex-1 text-left mr-2 truncate`}
`;

const StickyDiv = styled.div`
  ${tw`sticky flex flex-col self-stretch bg-white p-3.5 border-b border-b-gray-200 rounded-t-md`}
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

const SegmentPropertyDropdown: React.FunctionComponent<
  SegmentPropertyDropdownProps
> = ({ groups, value, properties, onChange, onClickCreateProperty }) => {
  const [filterInput, setFilterInput] = useState("");
  const [filteredGroups, setFilteredGroups] = useState(groups);
  const [filteredProperties, setFilteredProperties] = useState(properties);

  useEffect(() => {
    if (filterInput === "") {
      setFilteredGroups(groups);
      setFilteredProperties(properties);
      return;
    }

    const groupsClone = { ...groups };
    Object.entries(groupsClone).map(([group, groupProperties]) => {
      groupsClone[group] = groupProperties.filter((gp) =>
        gp.name.toLowerCase().includes(filterInput.toLowerCase())
      );
      return [group, groupProperties];
    });
    setFilteredGroups(groupsClone);

    setFilteredProperties(
      properties.filter((p) =>
        p.name.toLowerCase().includes(filterInput.toLowerCase())
      )
    );
  }, [filterInput, groups, properties]);

  const [isOpen, setIsOpen] = useState(false);

  const { features } = useAccountFeatures();

  let selectedProperty: SegmentConditionPropertyConfig | undefined = undefined;

  for (const [, groupProperties] of Object.entries(groups)) {
    for (const property of groupProperties) {
      if (property.id === value) {
        selectedProperty = property;
      }
    }
  }

  if (typeof value === "number") {
    const property = properties.find((p) => p.id === value);
    if (!property) {
      throw new Error("Invalid property");
    }

    selectedProperty = {
      id: segment_condition_property_enum.custom_property,
      entity: property.entity,
      name: property.name,
      type: "string",
      array: false,
      platforms: ALL_PLATFORMS,
      mergeTag: `property.${property.key}`,
    };
  }

  if (!selectedProperty) {
    throw new Error("Invalid property");
  }

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Tippy
      theme="light"
      className="dropdown-menu"
      maxWidth="none"
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              tether: false,
            },
          },
        ],
      }}
      offset={[0, 3]}
      onHidden={() => setFilterInput("")}
      content={
        <Menu>
          <StickyDiv>
            <SearchInput
              onClear={() => setFilterInput("")}
              tw="text-base w-full"
              value={filterInput}
              onInput={(i) => setFilterInput(i.currentTarget.value)}
            />
          </StickyDiv>
          <MenuBody>
            <>
              {Object.entries(filteredGroups).map(
                ([group, groupProperties]) =>
                  (groupProperties.length > 0 ||
                    filteredProperties.filter(
                      (p) => p.entity === group.toLowerCase()
                    ).length > 0) && (
                    <React.Fragment key={group}>
                      <PropertyGroup>
                        <Label>{group}</Label>
                        {groupProperties.map((p) => (
                          <Tippy
                            key={p.id}
                            content={p.description}
                            disabled={!p.description}
                          >
                            <PropertyButton
                              className={
                                (p.id === value && "active") || undefined
                              }
                              onClick={() => {
                                setIsOpen(false);
                                onChange(p.id);
                              }}
                            >
                              {p.name}
                            </PropertyButton>
                          </Tippy>
                        ))}
                      </PropertyGroup>
                      {features.custom_properties &&
                        group === "Subscriber" &&
                        (filteredProperties.filter(
                          (p) => p.entity === "subscriber"
                        ).length > 0 ||
                          filterInput.length === 0) &&
                        (!!properties.filter((p) => p.entity === "subscriber")
                          .length ||
                          !!onClickCreateProperty) && (
                          <>
                            <PropertySubGroup>
                              <Label>Properties</Label>
                              {filteredProperties
                                .filter((p) => p.entity === "subscriber")
                                .map((p) => (
                                  <PropertyButton
                                    key={p.id}
                                    className={
                                      (p.id === value && "active") || undefined
                                    }
                                    onClick={() => {
                                      setIsOpen(false);
                                      onChange(p.id);
                                    }}
                                  >
                                    {p.name}
                                  </PropertyButton>
                                ))}
                            </PropertySubGroup>
                            <>
                              {!!onClickCreateProperty &&
                                filterInput.length === 0 && (
                                  <span>
                                    <Button
                                      type="button"
                                      buttonType="alternate-secondary"
                                      size="sm"
                                      onClick={() => {
                                        setIsOpen(false);
                                        onClickCreateProperty(
                                          property_entity_enum.subscriber
                                        );
                                      }}
                                      tw="mb-2"
                                    >
                                      <FontAwesomeIcon icon={faPlus} /> Create
                                      property
                                    </Button>
                                  </span>
                                )}
                            </>
                          </>
                        )}
                      {features.custom_properties &&
                        group === "Subscription" &&
                        (filteredProperties.filter(
                          (p) => p.entity === "subscription"
                        ).length > 0 ||
                          filterInput.length === 0) &&
                        (!!properties.filter((p) => p.entity === "subscription")
                          .length ||
                          !!onClickCreateProperty) && (
                          <>
                            <PropertySubGroup>
                              <Label>Properties</Label>
                              {filteredProperties
                                .filter((p) => p.entity === "subscription")
                                .map((p) => (
                                  <PropertyButton
                                    key={p.id}
                                    className={
                                      (p.id === value && "active") || undefined
                                    }
                                    onClick={() => {
                                      setIsOpen(false);
                                      onChange(p.id);
                                    }}
                                  >
                                    {p.name}
                                  </PropertyButton>
                                ))}
                            </PropertySubGroup>
                            {!!onClickCreateProperty &&
                              filterInput.length === 0 && (
                                <span>
                                  <Button
                                    type="button"
                                    buttonType="alternate-secondary"
                                    size="sm"
                                    onClick={() => {
                                      setIsOpen(false);
                                      onClickCreateProperty(
                                        property_entity_enum.subscription
                                      );
                                    }}
                                    tw="mb-2"
                                  >
                                    <FontAwesomeIcon icon={faPlus} /> Create
                                    property
                                  </Button>
                                </span>
                              )}
                          </>
                        )}
                    </React.Fragment>
                  )
              )}
              {!Object.entries(filteredGroups).find(([i, g]) => g.length > 0) &&
                filteredProperties.length === 0 && (
                  <div tw="flex w-full items-center justify-center h-16">
                    <span tw="text-gray-400">No results.</span>
                  </div>
                )}
            </>
          </MenuBody>
        </Menu>
      }
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      interactive={true}
      appendTo={document.body}
      placement="bottom-start"
      arrow={false}
    >
      <DropdownButton type="button" onClick={handleClick} tw="w-full">
        <div tw="truncate">
          <strong tw="capitalize">{selectedProperty.entity}</strong> -{" "}
          {selectedProperty.name}
        </div>
        <div tw="ml-auto">
          <FontAwesomeIcon
            icon={faChevronDown}
            transform="down-1 shrink-6"
            tw="ml-2"
            color={theme`colors.gray.600`}
          />
        </div>
      </DropdownButton>
    </Tippy>
  );
};

export default SegmentPropertyDropdown;
