import { ApolloProvider } from "@apollo/client";
import { Route, Switch } from "react-router-dom";

import { createApolloClient } from "../../../app/apollo";
import CustomerPortalHome from "./CustomerPortalHome";
import Layout from "./Layout";
import Login from "./Login";
import ReactivateOffer from "./ReactivateOffer";

const CustomerPortalRoutes: React.FunctionComponent = () => (
  <Switch>
    <Route
      path={[
        "/p/portal/:clientId/reactivation/:reactivateToken", // DEPRECATED
        "/p/portal/:clientId/reactivate/:reactivateToken",
      ]}
      render={(props) => (
        <Layout mode="reactivate">
          <ApolloProvider
            client={createApolloClient(undefined, {
              "X-Hasura-Campaign-Token": "",
              "X-Hasura-Flow-Token": "",
              "X-Hasura-Portal-Token": props.match.params["reactivateToken"],
            })}
          >
            <ReactivateOffer />
          </ApolloProvider>
        </Layout>
      )}
    />
    <Route
      path="/p/portal/:clientId/session/:sessionToken"
      render={(props) =>
        props.match.params["sessionToken"] ? (
          <Layout>
            <ApolloProvider
              client={createApolloClient(
                undefined,
                {
                  "X-Hasura-Campaign-Token": "",
                  "X-Hasura-Flow-Token": "",
                  "X-Hasura-Portal-Token": props.match.params["sessionToken"],
                },
                props.match.params["sessionToken"]
              )}
            >
              <CustomerPortalHome />
            </ApolloProvider>
          </Layout>
        ) : null
      }
    />
    <Route
      path="/p/portal/:clientId/:sessionStatus?"
      render={(props) => (
        <Layout>
          <ApolloProvider
            client={createApolloClient(undefined, {
              "X-Hasura-Campaign-Token": "",
              "X-Hasura-Flow-Token": "",
              "X-Hasura-Portal-Token": props.match.params["clientId"],
            })}
          >
            <Login />
          </ApolloProvider>
        </Layout>
      )}
    />
  </Switch>
);

export default CustomerPortalRoutes;
