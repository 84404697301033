import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferFreeGiftMutation,
  UseUpdateOfferFreeGiftMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferFreeGift() {
  const [updateOfferFreeGift] = useMutation<
    UseUpdateOfferFreeGiftMutation,
    UseUpdateOfferFreeGiftMutationVariables
  >(gql`
    mutation UseUpdateOfferFreeGiftMutation(
      $offerId: Int!
      $variantId: String!
    ) {
      update_offer_free_gift_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { platform_variant_id: $variantId }
      ) {
        offer_id
        platform_variant_id
        offer {
          id
          offer_free_gift {
            offer_id
            platform_variant_id
          }
        }
      }
    }
  `);

  return updateOfferFreeGift;
}
