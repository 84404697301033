import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  platform_currency_enum,
} from "../../../__generated__/graphql";
import ProductSwapProductForm from "./ProductSwapProductForm";

export interface Product {
  platform_id: string;
  name: string | undefined;
  image_url: string | null | undefined;
  platform_variants: {
    platform_id: string | undefined;
    name: string | undefined;
    price: any;
    currency: platform_currency_enum;
    available: boolean;
    platform_variant_options: {
      key: string;
      value: string;
    }[];
  }[];
  platform_plan_ecommerce_products: {
    discount_amount: string | null;
    discount_type: string | null;
  }[];
}

interface ProductSwapFormProps {
  offer: FlowOfferFragment;
  setSelectedVariantId: Dispatch<SetStateAction<string | null>>;
  swappableProducts: FlowSubscriptionFragment | null;
}

const ProductSwapForm: React.FunctionComponent<ProductSwapFormProps> = ({
  offer,
  setSelectedVariantId,
  swappableProducts,
}) => {
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );

  const productList = useMemo((): Product[] => {
    if (!offer.offer_product_swap?.specific_products) {
      return (
        swappableProducts?.platform_subscription?.swappable_ecommerce_products.map(
          (p) => ({
            platform_id: p.platform_ecommerce_product?.platform_id || "",
            name: p.platform_ecommerce_product?.name || "Product",
            image_url: p.platform_ecommerce_product?.image_url,
            platform_variants:
              p.platform_ecommerce_product?.platform_variants.map((v) => ({
                platform_id: v.platform_id,
                name: v.name,
                price: v.price,
                currency: v.currency,
                available: v.available,
                platform_variant_options: v.platform_variant_options.map(
                  (o) => ({
                    key: o.key,
                    value: o.value,
                  })
                ),
              })) || [],
            platform_plan_ecommerce_products:
              p.platform_ecommerce_product?.platform_plan_ecommerce_products.map(
                (ppep) => ({
                  discount_amount: ppep.discount_amount || null,
                  discount_type: ppep.discount_type || null,
                })
              ) || [],
          })
        ) || []
      );
    }

    return (
      offer.offer_product_swap?.offer_product_swap_platform_ecommerce_products.map(
        (p) => ({
          platform_id: p.platform_ecommerce_product?.platform_id || "",
          name: p.platform_ecommerce_product?.name || "Product",
          image_url: p.platform_ecommerce_product?.image_url,
          platform_variants:
            p.offer_product_swap_platform_ecommerce_product_platform_variants
              .filter((v) => v.platform_variant?.available)
              .map((v) => ({
                platform_id: v.platform_variant?.platform_id,
                name: v.platform_variant?.name,
                price: v.platform_variant?.price,
                currency:
                  v.platform_variant?.currency || platform_currency_enum.usd,
                available: v.platform_variant?.available || false,
                platform_variant_options:
                  v.platform_variant?.platform_variant_options.map((o) => ({
                    key: o.key,
                    value: o.value,
                  })) || [],
              })) || [],
          platform_plan_ecommerce_products:
            p.platform_ecommerce_product?.platform_plan_ecommerce_products.map(
              (ppep) => ({
                discount_amount: ppep.discount_amount || null,
                discount_type: ppep.discount_type || null,
              })
            ) || [],
        })
      ) || []
    );
  }, [
    offer.offer_product_swap?.offer_product_swap_platform_ecommerce_products,
    offer.offer_product_swap?.specific_products,
    swappableProducts?.platform_subscription?.swappable_ecommerce_products,
  ]);

  useEffect(() => {
    // If there is exactly one product, select it by default
    if (productList.length === 1) {
      setSelectedProductId(productList[0].platform_id);
    }
  }, [productList]);

  const currentProduct = useMemo(
    () => ({
      name:
        swappableProducts?.platform_subscription?.platform_subscription_plans[0]
          ?.platform_variant?.platform_ecommerce_product?.name || "Unknown",
      variants: (
        swappableProducts?.platform_subscription?.platform_subscription_plans[0]
          ?.platform_variant?.platform_variant_options || []
      )
        .filter((o) => o.key !== "Title" && o.key !== "Default Title")
        .map((o) => o.value)
        .join(", "),
      price:
        swappableProducts?.platform_subscription?.platform_subscription_plans[0]
          ?.platform_variant?.price || "",
    }),
    [swappableProducts]
  );

  const getCurrentPriceForDisplay = (price: any) => {
    const currentPrice =
      swappableProducts?.platform_subscription?.platform_subscription_plans[0]
        ?.price;
    const currency =
      swappableProducts?.platform_subscription?.platform_subscription_plans[0]
        ?.platform_variant?.currency;

    const formatter = new Intl.NumberFormat(
      navigator.languages ? [...navigator.languages] : "en-US",
      {
        style: "currency",
        currency: currency || "usd",
        currencyDisplay: "symbol",
      }
    );

    if (
      !price ||
      currentPrice === undefined ||
      currentPrice === null ||
      Number(price) === Number(currentPrice)
    ) {
      return (
        <>
          {formatter.format(Number(price))}
          {currency === "usd" ? " USD" : ""}
        </>
      );
    }

    return (
      <>
        <span tw="line-through">{`${formatter.format(Number(price))}`}</span>
        <span>{`${formatter.format(Number(currentPrice))}${
          currency === "usd" ? " USD" : ""
        }`}</span>
      </>
    );
  };

  return (
    <>
      <div className="flow-current-variant">
        <div className="flow-current-variant__title">Current Product:</div>
        <div className="flow-current-variant__body">
          <div className="flow-current-variant__body__name">
            {currentProduct.name}
          </div>
          {currentProduct.variants !== "" && (
            <div className="flow-current-variant__body__variants">
              {currentProduct.variants}
            </div>
          )}
          <div className="flow-current-variant__body__price">
            {getCurrentPriceForDisplay(currentProduct.price)}
          </div>
        </div>
      </div>
      <div className="flow-product-swap-instructions">Swap to:</div>
      <div className="flow-product-swap">
        {productList
          .filter(
            (product) =>
              product.platform_variants.filter((variant) => variant.available)
                .length > 0
          )
          .map(
            (product) =>
              product.platform_id && (
                <ProductSwapProductForm
                  key={product.platform_id}
                  selected={
                    productList.length === 1 ||
                    product.platform_id === selectedProductId
                  }
                  product={product}
                  onChange={(productId, variantId) => {
                    setSelectedProductId(productId);
                    setSelectedVariantId(variantId);
                  }}
                />
              )
          )}
        {productList.filter((p) => !!p.platform_id).length === 0 && (
          <div className="flow-product-swap__placeholder">
            Product will display here
          </div>
        )}
      </div>
    </>
  );
};

export default ProductSwapForm;
