import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  CreateReactivateOfferMutation,
  CreateReactivateOfferMutationVariables,
} from "../../../__generated__/graphql";
import AppOfferFragment from "../../../common/fragments/AppOfferFragment";
import updateCache from "./updateCache";

export default function useCreateReactivateOffer() {
  const [createReactivateOffer] = useMutation<
    CreateReactivateOfferMutation,
    CreateReactivateOfferMutationVariables
  >(
    gql`
      mutation CreateReactivateOfferMutation(
        $object: offer_reactivate_insert_input!
      ) {
        insert_offer_reactivate_one(object: $object) {
          offer {
            ...AppOfferFragment
          }
        }
      }
      ${AppOfferFragment}
    `,
    {
      update: (cache, { data }) => {
        if (!data?.insert_offer_reactivate_one?.offer) {
          return;
        }

        updateCache(cache, data.insert_offer_reactivate_one.offer);
      },
    }
  );

  return createReactivateOffer;
}
