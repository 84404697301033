import { platform_enum } from "../../__generated__/graphql";

export default function addonLabel(
  platform: platform_enum,
  plural = false,
  article = false
) {
  switch (platform) {
    case "chargify":
      return plural ? "components" : article ? "a component" : "component";

    default:
      return plural ? "add-ons" : article ? "an add-on" : "add-on";
  }
}
