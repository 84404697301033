import { gql, useMutation } from "@apollo/client";

import {
  language_enum,
  translation_value_insert_input,
  UseUpdateOfferChangeFrequencyCreateTranslationMutation,
  UseUpdateOfferChangeFrequencyCreateTranslationMutationVariables,
  UseUpdateOfferChangeFrequencyMutation,
  UseUpdateOfferChangeFrequencyMutationVariables,
  UseUpdateOfferChangeFrequencyOptionMetadataMutation,
  UseUpdateOfferChangeFrequencyOptionMetadataMutationVariables,
  UseUpdateOfferChangeFrequencySetTranslationMutation,
  UseUpdateOfferChangeFrequencySetTranslationMutationVariables,
  UseUpdateOfferChangeFrequencyTranslationsMutation,
  UseUpdateOfferChangeFrequencyTranslationsMutationVariables,
} from "../../../__generated__/graphql";
import OfferDetailsFragment from "../../../common/fragments/OfferDetailsFragment";
import TranslationFragment from "../../../common/fragments/TranslationFragment";

export default function useUpdateOfferChangeFrequency() {
  const [updateOfferChangeFrequency] = useMutation<
    UseUpdateOfferChangeFrequencyMutation,
    UseUpdateOfferChangeFrequencyMutationVariables
  >(gql`
    mutation UseUpdateOfferChangeFrequencyMutation($offerId: Int!) {
      update_offer_change_frequency_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { offer_id: $offerId }
      ) {
        offer_id
        offer {
          id
          offer_change_frequency {
            offer_id
            offer_change_frequency_options(order_by: { position: asc }) {
              id
              position
              text_translation_id
            }
          }
        }
      }
    }
  `);

  const [createTranslation] = useMutation<
    UseUpdateOfferChangeFrequencyCreateTranslationMutation,
    UseUpdateOfferChangeFrequencyCreateTranslationMutationVariables
  >(gql`
    mutation UseUpdateOfferChangeFrequencyCreateTranslationMutation {
      insert_translation_one(object: {}) {
        id
      }
    }
  `);

  const [setTranslation] = useMutation<
    UseUpdateOfferChangeFrequencySetTranslationMutation,
    UseUpdateOfferChangeFrequencySetTranslationMutationVariables
  >(gql`
    mutation UseUpdateOfferChangeFrequencySetTranslationMutation(
      $offerChangeFrequencyOptionId: Int!
      $textTranslationId: Int!
    ) {
      update_offer_change_frequency_option_by_pk(
        pk_columns: { id: $offerChangeFrequencyOptionId }
        _set: { text_translation_id: $textTranslationId }
      ) {
        id
        text_translation {
          ...TranslationFragment
        }
      }
    }
    ${TranslationFragment}
  `);

  const [updateTranslation] = useMutation<
    UseUpdateOfferChangeFrequencyTranslationsMutation,
    UseUpdateOfferChangeFrequencyTranslationsMutationVariables
  >(gql`
    mutation UseUpdateOfferChangeFrequencyTranslationsMutation(
      $translationValues: [translation_value_insert_input!]!
    ) {
      insert_translation_value(
        objects: $translationValues
        on_conflict: {
          constraint: translation_value_pkey
          update_columns: [value]
        }
      ) {
        affected_rows
        returning {
          translation_id
          language
          value
        }
      }
    }
  `);

  const [updateOptionMetadata] = useMutation<
    UseUpdateOfferChangeFrequencyOptionMetadataMutation,
    UseUpdateOfferChangeFrequencyOptionMetadataMutationVariables
  >(gql`
    mutation UseUpdateOfferChangeFrequencyOptionMetadataMutation(
      $optionId: Int!
      $metadata: jsonb!
    ) {
      update_offer_change_frequency_option_by_pk(
        pk_columns: { id: $optionId }
        _set: { metadata: $metadata }
      ) {
        id
        metadata
        offer_change_frequency {
          offer {
            ...OfferDetailsFragment
          }
        }
      }
    }
    ${OfferDetailsFragment}
  `);

  return async (
    variables: UseUpdateOfferChangeFrequencyMutationVariables,
    optionTextTranslations: Record<number, Record<language_enum, any>>,
    optionMetadata: any[]
  ) => {
    let translationValues: translation_value_insert_input[] = [];

    const result = await updateOfferChangeFrequency({ variables });

    for (const [position, values] of Object.entries(optionTextTranslations)) {
      const option =
        result.data?.update_offer_change_frequency_by_pk?.offer
          .offer_change_frequency?.offer_change_frequency_options[
          Number(position)
        ];

      if (!option) {
        throw new Error();
      }

      let translationId = option.text_translation_id;

      if (!translationId) {
        const newTranslation = await createTranslation();
        if (!newTranslation.data?.insert_translation_one?.id) {
          throw new Error();
        }
        await setTranslation({
          variables: {
            offerChangeFrequencyOptionId: option.id,
            textTranslationId: newTranslation.data.insert_translation_one.id,
          },
        });
        translationId = newTranslation.data?.insert_translation_one?.id;
      }

      for (const [language, text] of Object.entries(values)) {
        translationValues.push({
          translation_id: translationId,
          language: language as language_enum,
          value: text,
        });
      }
    }

    await updateTranslation({
      variables: {
        translationValues,
      },
    });

    for (const [position, metadata] of optionMetadata.entries()) {
      const option =
        result.data?.update_offer_change_frequency_by_pk?.offer
          .offer_change_frequency?.offer_change_frequency_options[
          Number(position)
        ];

      if (!option) {
        continue;
      }

      await updateOptionMetadata({
        variables: {
          optionId: option.id,
          metadata,
        },
      });
    }
  };
}
