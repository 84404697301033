import { platform_enum } from "../../__generated__/graphql";

export enum PlatformFeature {
  AddOns,
  CancelSubscription,
  CancelSubscriptionAtEndOfPeriod,
  CollectPayment,
  Coupons,
  CouponsApplyToSubscriber,
  CouponsBillingCycleDuration,
  CouponsCustomDuration,
  CouponsExistingCoupons,
  CouponsFixedAmountDiscount,
  CouponsForeverDuration,
  CouponsMonthsDuration,
  CouponsOneTimeDuration,
  CouponsPercentageDiscount,
  CouponsNewCoupons,
  CouponsReplaceExistingCoupons,
  ChangeFrequency,
  ChangePlan,
  ChangePlanProration,
  ChangePlanChangeAtEndOfPeriod,
  ChangePlanApplyCoupon,
  ChangePlanRemoveAddOns,
  ChangePlanResetBillingDate,
  CustomerPortal,
  Downgrade,
  DowngradeAtEndOfPeriod,
  CustomerPortalBillingInformation,
  CustomerPortalEmailLogin,
  CustomerPortalInvoices,
  EndTrial,
  FreeGift,
  ModifySubscription,
  ModifySubscriptionChangeAtEndOfPeriod,
  ModifySubscriptionProration,
  NewspaperFeatures,
  PauseSubscription,
  PauseSubscriptionSpecifyLength,
  PauseSubscriptionForTemporalLength,
  PauseSubscriptionForBillingCycle,
  PauseSubscriptionIndefinitely,
  PauseSubscriptionPauseAtEndOfPeriod,
  PauseSubscriptionSpecifyEndDate,
  Products,
  ProductSwap,
  ReactivateOffer,
  RescheduleOrder,
  SignatureMandatory,
  SubscriptionItems,
  TrialExtension,
  TrialExtensionAllowWithoutTrial,
  Upgrade,
  UpgradeAtEndOfPeriod,
}

const features: Record<
  PlatformFeature,
  Record<Exclude<platform_enum, "custom">, boolean>
> = {
  [PlatformFeature.AddOns]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.CancelSubscription]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: true,
    openpay: true,
    ordergroove: true,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: true,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CancelSubscriptionAtEndOfPeriod]: {
    bold: false,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: true,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: true,
  },
  [PlatformFeature.CollectPayment]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.Coupons]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: true,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: true,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsApplyToSubscriber]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.CouponsCustomDuration]: {
    bold: true,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsForeverDuration]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: true,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsOneTimeDuration]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: true,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsMonthsDuration]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: true,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: true,
  },
  [PlatformFeature.CouponsBillingCycleDuration]: {
    bold: true,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: false,
    submarine: true,
    zuora: false,
  },
  [PlatformFeature.CouponsExistingCoupons]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsFixedAmountDiscount]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: true,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: true,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsNewCoupons]: {
    bold: true,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: true,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: true,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsPercentageDiscount]: {
    bold: true,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: true,
    paddle: false,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: true,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.CouponsReplaceExistingCoupons]: {
    bold: true,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.ChangeFrequency]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: true,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ChangePlan]: {
    bold: false,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: true,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: true,
    stripe: true,
    submarine: false,
    zuora: true,
  },
  [PlatformFeature.ChangePlanProration]: {
    bold: false,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: true,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ChangePlanChangeAtEndOfPeriod]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ChangePlanApplyCoupon]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: true,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ChangePlanRemoveAddOns]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ChangePlanResetBillingDate]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.CustomerPortal]: {
    bold: false,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: true,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.CustomerPortalBillingInformation]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.CustomerPortalEmailLogin]: {
    bold: false,
    braintree: true,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.CustomerPortalInvoices]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: true,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.Downgrade]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.DowngradeAtEndOfPeriod]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.EndTrial]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.FreeGift]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ModifySubscription]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ModifySubscriptionChangeAtEndOfPeriod]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.ModifySubscriptionProration]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: false,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.NewspaperFeatures]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.PauseSubscription]: {
    bold: true,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: true,
    openpay: true,
    ordergroove: false,
    paddle: true,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.PauseSubscriptionSpecifyLength]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: true,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: true,
    recurly: true,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.PauseSubscriptionForTemporalLength]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: true,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: true,
    submarine: true,
    zuora: true,
  },
  [PlatformFeature.PauseSubscriptionForBillingCycle]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.PauseSubscriptionIndefinitely]: {
    bold: true,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: true,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: true,
  },
  [PlatformFeature.PauseSubscriptionPauseAtEndOfPeriod]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: true,
  },
  [PlatformFeature.PauseSubscriptionSpecifyEndDate]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.Products]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: true,
    stripe: true,
    submarine: false,
    zuora: true,
  },
  [PlatformFeature.ProductSwap]: {
    bold: true,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: true,
    paddle: false,
    recharge: true,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: false,
    submarine: true,
    zuora: false,
  },
  [PlatformFeature.ReactivateOffer]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.RescheduleOrder]: {
    bold: true,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: true,
    paddle: false,
    recharge: true,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: false,
    submarine: true,
    zuora: false,
  },
  [PlatformFeature.SignatureMandatory]: {
    bold: true,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: true,
    paddle: false,
    recharge: true,
    recurly: false,
    stay: true,
    sticky_io: false,
    stripe: false,
    submarine: true,
    zuora: false,
  },
  [PlatformFeature.SubscriptionItems]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.TrialExtension]: {
    bold: false,
    braintree: false,
    chargebee: true,
    chargify: true,
    naviga: false,
    openpay: true,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: true,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.TrialExtensionAllowWithoutTrial]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: false,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: true,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.Upgrade]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
  [PlatformFeature.UpgradeAtEndOfPeriod]: {
    bold: false,
    braintree: false,
    chargebee: false,
    chargify: false,
    naviga: true,
    openpay: false,
    ordergroove: false,
    paddle: false,
    recharge: false,
    recurly: false,
    stay: false,
    sticky_io: false,
    stripe: false,
    submarine: false,
    zuora: false,
  },
};

interface PlatformHasFeatureOptions {
  defaultForCustom: boolean;
}

const platformHasFeature = (
  platform: platform_enum | undefined,
  feature: PlatformFeature,
  { defaultForCustom }: PlatformHasFeatureOptions = { defaultForCustom: false }
) =>
  !!platform && platform !== "custom"
    ? features[feature][platform]
    : defaultForCustom;

const platformsWithoutFeature = (feature: PlatformFeature): platform_enum[] => [
  platform_enum.custom,
  ...Object.entries(features[feature])
    .filter(([, hasFeature]) => !hasFeature)
    .map(([platform]) => platform as platform_enum),
];

const platformsWithFeature = (feature: PlatformFeature): platform_enum[] => [
  ...Object.entries(features[feature])
    .filter(([, hasFeature]) => !!hasFeature)
    .map(([platform]) => platform as platform_enum),
];

export { platformHasFeature, platformsWithoutFeature, platformsWithFeature };
