import { cloneDeep } from "lodash";
import { useState } from "react";

import {
  AppPropertyFragment,
  platform_enum,
  segment_condition_operator_enum,
  segment_condition_property_enum,
  SegmentConditionFragment,
} from "../../__generated__/graphql";
import Button from "../../common/form/Button";
import {
  getDefaultValue,
  getPropertiesFilteredForPlatform,
} from "../../common/segments/lib";
import groupSegmentProperties from "./lib/groupSegmentProperties";
import SegmentCondition from "./SegmentCondition";

interface InlineSegmentInputProps {
  platform: platform_enum;
  properties: AppPropertyFragment[];
  initialValue: SegmentConditionFragment | undefined;
  className?: string;
  onSave: (value: SegmentConditionFragment) => void;
  onCancel: () => void;
}

const InlineSegmentInput: React.FunctionComponent<InlineSegmentInputProps> = ({
  platform,
  properties,
  initialValue,
  className,
  onSave,
  onCancel,
}) => {
  const filteredProperties = getPropertiesFilteredForPlatform(platform);
  const groupedProperties = groupSegmentProperties(filteredProperties);
  const defaultInlineCondition: SegmentConditionFragment = {
    __typename: "segment_condition",
    id: -1,
    property: groupedProperties.Subscriber[0].id,
    operator: segment_condition_operator_enum.eq,
    value: "",
  };
  defaultInlineCondition.value = getDefaultValue(defaultInlineCondition);

  const [condition, setCondition] = useState(
    initialValue || defaultInlineCondition
  );

  const handlePropertyChange = (
    property: segment_condition_property_enum | number
  ) => {
    const newCondition = cloneDeep(condition);

    newCondition.property =
      typeof property === "number"
        ? segment_condition_property_enum.custom_property
        : property;
    newCondition.property_id = typeof property === "number" ? property : null;
    newCondition.operator = segment_condition_operator_enum.eq;
    newCondition.value = getDefaultValue(newCondition);

    if (newCondition.property_id) {
      const customProperty = properties.find(
        (p) => p.id === newCondition.property_id
      );
      if (!customProperty) {
        throw new Error(`Property not found: ${newCondition.property_id}`);
      }
      newCondition.custom_property = customProperty;
    }

    setCondition(newCondition);
  };

  const handleOperatorChange = (operator: segment_condition_operator_enum) => {
    const newCondition = cloneDeep(condition);

    if (
      (newCondition.operator === "in" || newCondition.operator === "nin") &&
      operator !== "in" &&
      operator !== "nin"
    ) {
      newCondition.value = "";
    }

    if (
      !["eq", "neq", "in", "nin"].includes(newCondition.operator) &&
      ["in", "nin"].includes(operator)
    ) {
      newCondition.value = undefined;
    }

    newCondition.operator = operator;

    setCondition(newCondition);
  };

  const handleValueChange = (val: any) => {
    const newCondition = cloneDeep(condition);
    newCondition.value = val;
    setCondition(newCondition);
  };

  return (
    <div tw="border border-divider rounded-lg p-2" className={className}>
      <SegmentCondition
        tw="p-0"
        platform={platform}
        index={0}
        condition={condition}
        properties={properties}
        groupedProperties={groupedProperties}
        canDelete={false}
        onPropertyChange={handlePropertyChange}
        onOperatorChange={(operator) =>
          handleOperatorChange(operator as segment_condition_operator_enum)
        }
        onValueChange={handleValueChange}
        onDelete={() => {}}
      />
      <div tw="mt-2 flex">
        <div tw="ml-auto">
          <Button
            type="button"
            buttonType="alternate-neutral"
            size="sm"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="button"
            buttonType="alternate-primary"
            size="sm"
            onClick={() => onSave(condition)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InlineSegmentInput;
