import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import { platform_enum } from "../../../../__generated__/graphql";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldError from "../../../../common/form/FieldError";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import PillRadio from "../../../../common/PillRadio";
import addonLabel from "../../../../common/platform/addonLabel";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import MultipleAddOnIdsDropdown from "../../../segments/MultipleAddOnIdsDropdown";
import MultiplePlanIdsDropdown from "../../../segments/MultiplePlanIdsDropdown";
import MultipleTextsInput from "../../../segments/MultipleTextsInput";
import { ModifySubscriptionOfferFormValues } from "../types";

interface ModifySubscriptionDetailsFormPartialProps {
  mode: FormMode;
  platform: platform_enum;
  isSubmitting: boolean;
}

const ModifySubscriptionDetailsFormPartial: React.FunctionComponent<
  ModifySubscriptionDetailsFormPartialProps
> = ({ mode, platform, isSubmitting }) => {
  const { register, formState, setValue, watch, control, trigger } =
    useTranslatableFormContext<ModifySubscriptionOfferFormValues>();

  const values = watch();

  const [removeAddonsType, setRemoveAddonsType] = useState(
    values.removeAllAddons ? "all" : "specific"
  );

  useEffect(() => {
    if (values.modifyAt === "period_end") {
      setValue("prorate", false);
    }
  }, [values.modifyAt, setValue]);

  const addPlans: string[] = JSON.parse(values.addPlans);
  const removePlans: string[] = JSON.parse(values.removePlans);
  const addAddons: string[] = JSON.parse(values.addAddons);
  const removeAddons: string[] = JSON.parse(values.removeAddons);
  const addCustomPlans: string[] = JSON.parse(values.addCustomPlans);
  const removeCustomPlans: string[] = JSON.parse(values.removeCustomPlans);

  const validate = () => {
    if (
      !addPlans.length &&
      !removePlans.length &&
      !addAddons.length &&
      !removeAddons.length &&
      !addCustomPlans.length &&
      !removeCustomPlans.length
    ) {
      return "At least one option must be selected.";
    }
  };

  return (
    <FieldSet title="Modify details">
      {platformHasFeature(platform, PlatformFeature.SubscriptionItems) && (
        <fieldset disabled={isSubmitting || mode === "edit"}>
          <FieldRow>
            <FieldLabel>
              <label>Add plans</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="addPlans"
                render={({ field }) => (
                  <MultiplePlanIdsDropdown
                    value={addPlans}
                    onChange={(value) => {
                      field.onChange(JSON.stringify(value));

                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    excludeIds={removePlans}
                    width="240px"
                  />
                )}
                rules={{
                  validate: validate,
                }}
              />
              <FieldHint>
                Add each of the selected plans to the subscription.
              </FieldHint>
            </FieldInput>
          </FieldRow>

          <FieldRow>
            <FieldLabel>
              <label>Remove plans</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="removePlans"
                render={({ field }) => (
                  <MultiplePlanIdsDropdown
                    value={removePlans}
                    onChange={(value) => {
                      field.onChange(JSON.stringify(value));

                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    excludeIds={addPlans}
                    width="240px"
                  />
                )}
              />
              <FieldHint>
                Remove each of the selected plans from the subscription, if they
                exist.
              </FieldHint>
            </FieldInput>
          </FieldRow>
        </fieldset>
      )}

      {platformHasFeature(platform, PlatformFeature.AddOns) && (
        <fieldset disabled={mode === "edit"}>
          <FieldRow>
            <FieldLabel>
              <label>Add {addonLabel(platform, true)}</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="addAddons"
                render={({ field }) => (
                  <MultipleAddOnIdsDropdown
                    value={addAddons}
                    platform={platform}
                    onChange={(value) => {
                      field.onChange(JSON.stringify(value));

                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    excludeIds={removeAddons}
                    width="240px"
                  />
                )}
                rules={{
                  validate: validate,
                }}
              />
              <FieldHint>
                Add each of the selected {addonLabel(platform, true)} to the
                subscription.
              </FieldHint>
            </FieldInput>
          </FieldRow>

          <FieldRow>
            <FieldLabel>
              <label>Remove</label>
            </FieldLabel>
            <FieldInput>
              <PillRadio
                value={removeAddonsType}
                options={[
                  {
                    label: `Specific ${addonLabel(platform, true)}`,
                    value: "specific",
                    tooltip: `Remove specific ${addonLabel(
                      platform,
                      true
                    )} from the subscription`,
                  },
                  {
                    label: `All ${addonLabel(platform, true)}`,
                    value: "all",
                    tooltip: `Remove all ${addonLabel(
                      platform,
                      true
                    )} from the subscription`,
                  },
                ]}
                onChange={(v) => {
                  setRemoveAddonsType(v);
                  setValue("removeAllAddons", v === "all");
                }}
              />
            </FieldInput>
          </FieldRow>
          <FieldRow css={removeAddonsType === "all" ? tw`hidden` : undefined}>
            <FieldLabel>
              <label tw="capitalize">{addonLabel(platform, true)}</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="removeAddons"
                render={({ field }) => (
                  <MultipleAddOnIdsDropdown
                    value={removeAddons}
                    platform={platform}
                    onChange={(value) => {
                      field.onChange(JSON.stringify(value));

                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    excludeIds={addAddons}
                    width="240px"
                  />
                )}
              />
              <FieldHint>
                Remove each of the selected {addonLabel(platform, true)} from
                the subscription, if they exist.
              </FieldHint>
            </FieldInput>
          </FieldRow>

          <div>
            <FieldError
              tw="mb-4"
              error={
                formState.errors.addPlans ||
                formState.errors.addAddons ||
                formState.errors.addCustomPlans
              }
            />
          </div>
        </fieldset>
      )}

      {platform === "custom" && (
        <fieldset disabled={mode === "edit"}>
          <FieldRow>
            <FieldLabel>
              <label>Add plans</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="addCustomPlans"
                render={({ field }) => (
                  <MultipleTextsInput
                    value={addCustomPlans}
                    onChange={(value) => {
                      field.onChange(JSON.stringify(value));

                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    placeholder="Enter a plan ID…"
                  />
                )}
              />
              <FieldHint>
                Plan IDs from your billing system to add to the subscription.
              </FieldHint>
            </FieldInput>
          </FieldRow>

          <FieldRow>
            <FieldLabel>
              <label>Remove plans</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="removeCustomPlans"
                render={({ field }) => (
                  <MultipleTextsInput
                    value={removeCustomPlans}
                    onChange={(value) => {
                      field.onChange(JSON.stringify(value));

                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                    placeholder="Enter a plan ID…"
                  />
                )}
              />
              <FieldHint>
                Plan IDs from your billing system to remove from the
                subscription.
              </FieldHint>
            </FieldInput>
          </FieldRow>
        </fieldset>
      )}

      {platformHasFeature(
        platform,
        PlatformFeature.ModifySubscriptionChangeAtEndOfPeriod
      ) && (
        <FieldRow>
          <FieldLabel>
            <label>Modify at</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="modifyAt"
              render={({ field }) => (
                <PillRadio
                  value={field.value}
                  options={[
                    {
                      label: "Immediately",
                      value: "immediately",
                      tooltip: "Apply the specified modifications immediately",
                    },
                    {
                      label: "End of period",
                      value: "period_end",
                      tooltip:
                        "Apply the specified modifications at the end of the billing period",
                    },
                  ]}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
      )}
      {platformHasFeature(
        platform,
        PlatformFeature.ModifySubscriptionProration
      ) && (
        <FieldRow>
          <FieldLabel>
            <label>Proration</label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("prorate")}
              id="prorate"
              type="checkbox"
              disabled={values.modifyAt === "period_end"}
            />
            <label htmlFor="prorate">Apply prorated charges or credits</label>
          </FieldCheckboxInput>
        </FieldRow>
      )}
    </FieldSet>
  );
};

export default ModifySubscriptionDetailsFormPartial;
