import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw from "twin.macro";

import { offer_goal_enum, offer_type_enum } from "../../__generated__/graphql";
import { TranslatedForms } from "../../common/form/useTranslatableForm";
import { OfferFormValues } from "./forms/types";

const Wrapper = tw.div`text-lg text-type-light p-4 px-12 mt-6`;
const HelpContainer = tw.div`text-gray-800 pb-5 flex`;
const HelpIcon = tw.div`mr-2`;
const HelpText = tw.div``;

export interface PreviewHintProps {
  formValues?: TranslatedForms<OfferFormValues>;
  previewType?: offer_type_enum;
  goal?: offer_goal_enum;
}

const PreviewHint: React.FunctionComponent<PreviewHintProps> = ({
  formValues,
  previewType,
  goal = offer_goal_enum.retention,
}) => {
  if (formValues || !previewType) {
    return null;
  }

  const text = (() => {
    switch (String(previewType)) {
      case "coupon":
        return goal === offer_goal_enum.conversion ? (
          <>
            Use a coupon offer to incentivize customers to convert from a trial
            to a paid subscription.
          </>
        ) : (
          <>
            Use a coupon offer to incentivize subscribers to keep their
            subscription active.
          </>
        );

      case "change_plan":
        return goal === offer_goal_enum.conversion ? (
          <>
            Offer a change of pricing plan to empower subscribers to find the
            right fit for your product.
          </>
        ) : (
          <>Offer a change of pricing plan as an alternative to canceling.</>
        );

      case "free_gift":
        return <>Give subscribers a free gift.</>;

      case "modify_subscription":
        return (
          <>
            Add or remove line items from a customer's subscription as an
            alternative to canceling.
          </>
        );

      case "trial_extension":
        return (
          <>Offer subscribers an automatic extension of their free trial.</>
        );

      case "pause_subscription":
        return (
          <>
            Allow subscribers to pause payment collection for their subscription
            for a configurable duration.
          </>
        );

      case "upgrade":
        return <>Offer a plan upgrade as an alternative to canceling.</>;

      case "downgrade":
        return <>Offer a plan downgrade as an alternative to canceling.</>;

      case "product_swap":
        return <>Allow subscribers to swap to a different product.</>;

      case "reschedule_order":
        return <>Allow subscribers to skip or reschedule their next order.</>;

      case "change_frequency":
        return (
          <>
            Allow subscribers to change the order frequency for their
            subscription.
          </>
        );

      case "custom":
        return <>Implement custom logic with a configurable custom offer.</>;
    }
  })();

  return (
    <Wrapper>
      <HelpContainer>
        <HelpIcon>
          <FontAwesomeIcon icon={faInfoCircle} />
        </HelpIcon>
        <HelpText>{text}</HelpText>
      </HelpContainer>
    </Wrapper>
  );
};

export default PreviewHint;
