import {
  language_enum,
  offer_goal_enum,
  offer_style_enum,
  offer_timer_mode_enum,
  translation_value_format_enum,
} from "../../../__generated__/graphql";
import {
  ChangeFrequencyOfferFormValues,
  ChangePlanOfferFormValues,
  CouponOfferFormValues,
  CustomOfferFormValues,
  DowngradeOfferFormValues,
  FreeGiftOfferFormValues,
  ModifySubscriptionOfferFormValues,
  PauseSubscriptionOfferFormValues,
  ProductSwapOfferFormValues,
  ReactivateOfferFormValues,
  RescheduleOrderOfferFormValues,
  TrialExtensionOfferFormValues,
  UpgradeOfferFormValues,
} from "../forms/types";
import {
  getChangeFrequencyDefaults,
  getChangePlanDefaults,
  getCouponDefaults,
  getCustomDefaults,
  getDowngradeDefaults,
  getFreeGiftDefaults,
  getModifySubscriptionDefaults,
  getPauseSubscriptionDefaults,
  getProductSwapDefaults,
  getReactivateDefaults,
  getRescheduleOrderDefaults,
  getTrialExtensionDefaults,
  getUpgradeDefaults,
} from "./offerDefaults";

const defaultProps = {
  allowOverride: false,
  confirmationEnabled: false,
  confirmationContent: "",
  confirmationConfirmButtonText: "",
  confirmationCancelButtonText: "",
  contentFormat: translation_value_format_enum.lexical,
  image: "",
  metadata: JSON.stringify([]),
  name: "Offer",
  showBanner: false,
  style: offer_style_enum.modal,
  tags: [],
  autopilot: false,
};

const defaultConversionProps = {
  timerMode: null,
  startTime: null,
  endTime: null,
  offerLength: null,
  countdownTimer: false,
};

export const generatePreviewCouponOfferFormValues = (
  goal: offer_goal_enum
): CouponOfferFormValues => {
  const values = getCouponDefaults(goal, language_enum.en_us);

  return {
    ...defaultProps,
    ...defaultConversionProps,
    type: "coupon",
    showBanner: true,
    headline: values.headline,
    content: JSON.stringify(values.content),
    buttonText: values.buttonText,
    couponId: null,
    couponType: "percentage",
    amountOff: 25,
    duration: "repeating",
    durationInterval: null,
    durationCount: null,
    months: 3,
    applyTo: "subscription",
    allowCouponOverride: false,
    endTrial: false,
    timerMode:
      goal === offer_goal_enum.conversion
        ? offer_timer_mode_enum.from_first_seen
        : "",
    offerLength: goal === offer_goal_enum.conversion ? "24h" : "",
    countdownTimer: true,
  };
};

export const generatePreviewChangeFrequencyOfferFormValues =
  (): ChangeFrequencyOfferFormValues => {
    const values = getChangeFrequencyDefaults(language_enum.en_us);

    return {
      ...defaultProps,
      type: "change_frequency",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      option1Present: true,
      option1Interval: "month",
      option1IntervalCount: null,
      option1Text: null,
      option1Metadata: JSON.stringify([]),
      option2Present: false,
      option2Interval: "month",
      option2IntervalCount: null,
      option2Text: null,
      option2Metadata: JSON.stringify([]),
      option3Present: false,
      option3Interval: "month",
      option3IntervalCount: null,
      option3Text: null,
      option3Metadata: JSON.stringify([]),
    };
  };

export const generatePreviewChangePlanOfferFormValues =
  (): ChangePlanOfferFormValues => {
    const values = getChangePlanDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      ...defaultConversionProps,
      type: "change_plan",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      planId: "",
      planName: null,
      couponId: "",
      prorate: true,
      changeAt: "immediately",
      resetBillingDate: false,
    };
  };

export const generatePreviewCustomOfferFormValues =
  (): CustomOfferFormValues => {
    const values = getCustomDefaults(language_enum.en_us);

    return {
      ...defaultProps,
      type: "custom",
      showBanner: true,
      headline: "Custom offer",
      content: JSON.stringify(values.content),
      buttonText: values?.buttonText || "",
      timerMode: "",
      startTime: null,
      endTime: null,
      offerLength: "",
      countdownTimer: false,
    };
  };

export const generatePreviewDowngradeOfferFormValues =
  (): DowngradeOfferFormValues => {
    const values = getDowngradeDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      type: "downgrade",
      planId: "",
      planName: null,
      changeAt: "immediately",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      couponId: null,
    };
  };

export const generatePreviewFreeGiftOfferFormValues =
  (): FreeGiftOfferFormValues => {
    const values = getFreeGiftDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      ...defaultConversionProps,
      type: "free_gift",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      productId: null,
      productName: null,
      productImageUrl: null,
      variantId: null,
      variantName: null,
    };
  };

export const generatePreviewModifySubscriptionOfferFormValues =
  (): ModifySubscriptionOfferFormValues => {
    const values = getModifySubscriptionDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      type: "modify_subscription",
      headline: "Update your subscription",
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      addPlans: JSON.stringify([]),
      removePlans: JSON.stringify([]),
      addAddons: JSON.stringify([]),
      removeAllAddons: false,
      removeAddons: JSON.stringify([]),
      addCustomPlans: JSON.stringify([]),
      removeCustomPlans: JSON.stringify([]),
      prorate: true,
      modifyAt: "immediately",
    };
  };

export const generatePreviewPauseSubscriptionOfferFormValues =
  (): PauseSubscriptionOfferFormValues => {
    const values = getPauseSubscriptionDefaults(language_enum.en_us);

    return {
      ...defaultProps,
      type: "pause_subscription",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      pauseAt: "immediately",
      option1Present: true,
      option1Interval: "month",
      option1IntervalCount: null,
      option1Text: null,
      option1Metadata: JSON.stringify([]),
      option2Present: false,
      option2Interval: "month",
      option2IntervalCount: null,
      option2Text: null,
      option2Metadata: JSON.stringify([]),
      option3Present: false,
      option3Interval: "month",
      option3IntervalCount: null,
      option3Text: null,
      option3Metadata: JSON.stringify([]),
    };
  };

export const generatePreviewTrialExtensionOfferFormValues =
  (): TrialExtensionOfferFormValues => {
    const values = getTrialExtensionDefaults(language_enum.en_us);

    return {
      ...defaultProps,
      ...defaultConversionProps,
      type: "trial_extension",
      days: 14,
      allowWithoutTrial: false,
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
    };
  };

export const generatePreviewUpgradeOfferFormValues =
  (): UpgradeOfferFormValues => {
    const values = getUpgradeDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      type: "upgrade",
      planId: "",
      planName: null,
      changeAt: "immediately",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
    };
  };

export const generatePreviewProductSwapOfferFormValues =
  (): ProductSwapOfferFormValues => {
    const values = getProductSwapDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      type: "product_swap",
      specificProducts: false,
      products: [], //
      showBanner: false,
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
    };
  };

export const generatePreviewRescheduleOrderOfferFormValues =
  (): RescheduleOrderOfferFormValues => {
    const values = getRescheduleOrderDefaults(language_enum.en_us);

    return {
      ...defaultProps,
      type: "reschedule_order",
      skipEnabled: true,
      rescheduleEnabled: true,
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: "",
      skipButtonText: values.skipButtonText,
      rescheduleButtonText: values.rescheduleButtonText,
    };
  };

export const generatePreviewReactivateOfferFormValues =
  (): ReactivateOfferFormValues => {
    const values = getReactivateDefaults(language_enum.en_us, true);

    return {
      ...defaultProps,
      ...defaultConversionProps,
      type: "reactivate",
      headline: values.headline,
      content: JSON.stringify(values.content),
      buttonText: values.buttonText,
      couponId: null,
      couponType: "percentage",
      amountOff: 25,
      duration: "repeating",
      durationInterval: null,
      durationCount: null,
      months: 3,
      applyTo: "subscription",
      allowCouponOverride: false,
      endTrial: false,
    };
  };
