import Tippy from "@tippyjs/react";
import tw, { styled } from "twin.macro";

import { integration_type_enum } from "../../__generated__/graphql";
import SegmentIntegrationIcon from "./SegmentIntegrationIcon";

const Box = styled.div<{ isEditable: boolean; isOn: boolean }>`
  ${tw`font-semibold px-2 rounded-md text-white m-1 bg-purple-600 truncate`}
  ${(props) => !props.isOn && tw`bg-gray-400!`}
  ${(props) => props.isEditable && tw`cursor-pointer`}
`;

interface SegmentRuleBoxProps {
  isEditable: boolean;
  isOn: boolean;
  segment: {
    name: string;
    integration?: {
      type: integration_type_enum;
    } | null;
  };
  onClick?: () => void;
}

const SegmentRuleBox: React.FunctionComponent<SegmentRuleBoxProps> = ({
  isEditable,
  isOn,
  segment,
  onClick,
}) => (
  <Tippy
    content={
      !!segment.integration ? (
        <>
          <span tw="capitalize">{segment.integration.type}</span> segment
        </>
      ) : undefined
    }
    disabled={!isEditable || !segment.integration}
  >
    <Box isEditable={isEditable} isOn={isOn} onClick={onClick}>
      {segment.name}
      {!!segment.integration && (
        <SegmentIntegrationIcon
          integrationType={segment.integration.type}
          color="light"
        />
      )}
    </Box>
  </Tippy>
);

export default SegmentRuleBox;
