import {
  faArrowUpFromLine,
  faCalendarAlt,
  faCalendarClock,
  faCalendarLinesPen,
  faMap,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronsDown,
  faChevronsUp,
  faCut,
  faFlask,
  faHandHoldingBox,
  faPauseCircle,
  faReceipt,
  faSwap,
} from "@fortawesome/pro-solid-svg-icons";
import { UnreachableCaseError } from "ts-essentials";

import { offer_type_enum } from "../../../__generated__/graphql";

export default function offerIcon(type: `${offer_type_enum}`) {
  switch (type) {
    case "coupon":
      return faCut;

    case "change_frequency":
      return faCalendarLinesPen;

    case "change_plan":
      return faMap;

    case "free_gift":
      return faHandHoldingBox;

    case "modify_subscription":
      return faReceipt;

    case "trial_extension":
      return faCalendarAlt;

    case "pause_subscription":
      return faPauseCircle;

    case "upgrade":
      return faChevronsUp;

    case "downgrade":
      return faChevronsDown;

    case "product_swap":
      return faSwap;

    case "reschedule_order":
      return faCalendarClock;

    case "custom":
      return faFlask;

    case "reactivate":
      return faArrowUpFromLine;

    default:
      throw new UnreachableCaseError(type);
  }
}
